/**
 * useContext Hook for Draft, Terms Explanation and Abstract Page
 *
 *
 */

import { createContext, useState } from "react";
import API from "../Utils/API";

const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
  const [documentData, setDocumentData] = useState({
    value: null,
    loading: true,
    error: false,
  });
  const [documentTitle, setDocumentTitle] = useState("");
  const [titleWithId, setTitleWithId] = useState("");
  const loadDocument = async () => {
    if (!documentData.value) {
      try {
        const document = await API.getDocumentTitleAndContent();
        setDocumentData({
          value: document.result,
          loading: false,
          error: false,
        });
        setDocumentTitle(document.title);
        setTitleWithId(document.titleWithId);
      } catch (error) {
        setDocumentData({ value: error, loading: false, error: true });
      }
    }
  };
  const contextValue = {
    documentData,
    setDocumentData,
    loadDocument,
    documentTitle,
    setDocumentTitle,
    titleWithId,
    setTitleWithId,
  };
  return (
    <DocumentContext.Provider value={contextValue}>
      {children}
    </DocumentContext.Provider>
  );
};
export default DocumentContext;
