import { ListItemButton, ListItemIcon, Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme/theme";

const SideMenuItem = ({ onClick, icon, itemText }) => {
  const marginLeft = icon ? "1rem" : "2.25rem";

  return (
    <ListItemButton onClick={onClick} sx={{}} key={itemText}>
      <ListItemIcon color={theme.palette.midGrey[1]} sx={{ fontSize: 20 }}>
        {icon}
      </ListItemIcon>
      <Typography
        variant="Input"
        color={theme.palette.midGrey[1]}
        fontSize="1rem"
        sx={{ marginLeft: { marginLeft } }}
      >
        {itemText}
      </Typography>
    </ListItemButton>
  );
};

export default SideMenuItem;
