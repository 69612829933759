import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

const TagButtons = (props) => {
  const tagList = props.tagList;
  const selectedTag = props.selectedTag;

  const handleClick = (e) => {
    // setIsSelected(selectedTag);
    const tag = e.target.textContent;
    props.onSelect(tag);
  };

  return (
    tagList &&
    tagList.map((tag, index) => {
      return (
        <Button
          key={index}
          variant="contained"
          sx={{
            backgroundColor: selectedTag === tag ? "#737B82" : "#FFFFFF",
            border: "1px solid #D4D4D4",
            borderRadius: "20px",
            color: selectedTag === tag ? "#FFFFFF" : "#737B82",
            maxHeight: "41px",
            maxWidth: "194px",
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontSize: ".75rem",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#737B82",
              color: "#D4D4D4",
              boxShadow: "none",
            },
          }}
          onClick={handleClick}
        >
          {tag === "+" ? <AddIcon /> : tag}
        </Button>
      );
    })
  );
};

export default TagButtons;
