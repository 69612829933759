import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import HoverCard from "./HoverCard";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

//   const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
//   ];
export default function EntityTable(props) {
  console.log("Entity table Props ", props);
  const [lastEntity, setLast] = useState("");
  const rows = props.rows;
  const scrollToTerm = (id, type) => {
    // if (lastEntity) {
    // 	let last = document.getElementById(lastEntity);
    // 	last.style.backgroundColor = "#FFFFFF";
    // }
    // if (type === "entity") {
    // 	id = id.split("_")[1];
    // }
    // let target = document.getElementById(id);
    // console.log("Target ", id);
    // target.style.backgroundColor = "#E2F5FF";
    // target.scrollIntoView({ block: "start", behavior: "smooth" });
    // setLast(id);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableBody>
          {rows.length > 0 &&
            rows.map((row) => {
              return (
                <TableRow
                  onClick={() => {
                    scrollToTerm(row.id, row.type);
                  }}
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    width: "100%",
                    backgroundColor: row.color,
                    cursor: "pointer",
                    "&:hover, &:active": { backgroundColor: "#E2F5FF" },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ width: "50%", border: 0 }}
                  >
                    {row.term}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      whiteSpace: "pre-wrap",
                      overflow: "auto",
                      width: "50%",
                      border: 0,
                    }}
                  >
                    {row.type === "entity" ? (
                      <HoverCard
                        content={row.explanation}
                        url={row.content}
                      ></HoverCard>
                    ) : (
                      row.explanation
                    )}
                  </TableCell>
                  {/* {row.type === 'entity' ? <HoverCard content={row.content} url={row.content}></HoverCard> : <TableCell>{row.content}</TableCell>} */}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
