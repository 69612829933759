import ExpandMoreIcon from "@mui/icons-material/EmojiObjectsOutlined";
import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/EmojiObjects";
import MuiAccordion from "@mui/material/Accordion";
import HelpCenterIconOutlined from "@mui/icons-material/QuizOutlined";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const accordionCard = {
  padding: "8px 0px 0px",

  width: "100%",
  verticalAlign: "baseline",
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: 36,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 8,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1.5),
}));

export default function SimpleAccordion(props) {
  const [expanded, setExpanded] = React.useState("");
  const [preview, setPreview] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const scrollToTerm = (pos) => {
    let target = document.getElementById("term_" + pos.id);
    // console.log(target);
    if (target) {
      target.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };
  return (
    <div sx={{ width: "100%", m: 0, p: 0 }} id="accordions-container">
      {props.accordions.map((accordion, index) => {
        return (
          <Box
            onClick={() => {
              scrollToTerm(accordion);
            }}
            key={accordion.recommendation_id}
            style={accordionCard}
            sx={{ display: accordion.active ? "inline" : "none" }}
          >
            <Accordion
              sx={{ borderRadius: "2rem" }}
              expanded={expanded === "panel" + accordion.recommendation_id}
              onChange={handleChange("panel" + accordion.recommendation_id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#F8C218" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{accordion.clause_type}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="column"
                  justifyContent="left"
                  alignItems="left"
                  spacing={1}
                >
                  <Typography>{accordion.title}</Typography>

                  {/* <Typography variant="">{accordion.content}</Typography> */}
                  <Typography sx={{ fontSize: "2vh", color: "#525252" }}>
                    {accordion.reason}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "2vh",
                      color: "#525252",
                      background: "rgba(255, 203, 33, 0.6)",
                    }}
                  >
                    {accordion.recommendedClause}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px !important",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "1vh",
                        padding: "0px !important",
                        verticalAlign: "middle",
                      }}
                    >
                      <HelpCenterIconOutlined
                        sx={{
                          height: "1rem",
                          width: "1rem",
                          verticalAlign: "text-top",
                        }}
                      ></HelpCenterIconOutlined>
                      <Typography sx={{ pl: 1 }} variant="span">
                        Learn more
                      </Typography>
                    </Box>
                    {preview === "panel" + accordion.recommendation_id ? (
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "#F8C218",
                          height: "2rem",
                          borderRadius: "1rem",
                        }}
                        onClick={() => {
                          props.confirmFunction(accordion);
                        }}
                      >
                        Confirm
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "#F8C218",
                          height: "2rem",
                          borderRadius: "1rem",
                        }}
                        onClick={() => {
                          props.addFunction(accordion);
                          setPreview("panel" + accordion.recommendation_id);
                        }}
                      >
                        Preview
                      </Button>
                    )}
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
    </div>
  );
}
