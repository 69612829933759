import Button from "@mui/material/Button";
import {
  Typography,
  Box,
  List,
  ListItemButton,
  ListSubheader,
  Divider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../theme/theme";
import { useState } from "react";
import { Close, Home, ReviewsOutlined } from "@mui/icons-material";
import styled from "styled-components";
import SideMenuItem from "./Component/DocumentNavItem";
import { Icon } from "@iconify/react";

const DocumentNav = styled(List)({
  "&. MuiList-root": {
    display: "flex",
    padding: 0,
    margin: 0,
  },
  "& .MuiListSubheader-root": {
    padding: 0,
    paddingLeft: "2.75rem",
  },
  "& .MuiListItemButton-root": {
    margin: 0,
    padding: 0,
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    margin: 0,
    padding: 0,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const SideMenu = (props) => {
  const [open, setopen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = () => {
    setopen(!open);
  };

  const handleNavigate = (page) => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    if (!page || page === "") {
      return;
    }
    let path = `/portal/${page}?id=${id}`;
    if (page === "home") {
      path = `/portal/${page}`;
    }

    navigate(path);
  };
  const handleEditNavigate = (page) => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    let path = `/portal/draft?id=${id}`;
    window.document.title = "Contracfy";
    navigate(path);
  };

  const functionList = [
    {
      type: "close",
      subheading: "",
      functions: (
        <>
          <SideMenuItem
            onClick={() => handleClick()}
            icon={<Close />}
            itemText="Close Menu"
          />
        </>
      ),
    },
    {
      type: "home",
      subheading: "",
      functions: (
        <SideMenuItem
          key={"home"}
          onClick={() => handleNavigate("home")}
          icon={<Home />}
          itemText="Home"
        />
      ),
    },
    {
      type: "features",
      subheading: "Features",
      functions: (
        <>
          <SideMenuItem
            key={"edit"}
            onClick={() => handleEditNavigate("drafting")}
            icon={<Icon icon="fluent:document-edit-20-regular" />}
            itemText="Edit"
          />
          <SideMenuItem
            key={"correction"}
            onClick={() => handleNavigate("drafting")}
            icon={<Icon icon="icon-park-solid:check-correct" />}
            itemText="Correction"
          />
          <SideMenuItem
            key={"term-explanation"}
            onClick={() => handleNavigate("terms-explanation")}
            icon={<ReviewsOutlined />}
            itemText="Term Explanation"
          />
          <SideMenuItem
            key={"absract"}
            onClick={() => handleNavigate("abstract")}
            icon={<Icon icon="icon-park-outline:compression" />}
            itemText="Abstract"
          />
        </>
      ),
    },
    {
      type: "document-edit",
      subheading: "Document Edit",
      functions: (
        <>
          <SideMenuItem
            onClick={() => handleClick()}
            icon={<Icon icon="material-symbols:undo" />}
            itemText="Undo"
          />
          <SideMenuItem
            onClick={() => handleClick()}
            icon={<Icon icon="material-symbols:redo" />}
            itemText="Redo"
          />
        </>
      ),
    },
    {
      type: "account",
      subheading: <>Account Subscription</>,
      functions: (
        <>
          <ListItemButton
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "2.25rem !important",
            }}
          >
            <Typography
              variant="Subtitle"
              color={theme.palette.midGrey[1]}
              fontWeight={700}
            >
              Simon Chan
            </Typography>
            <Typography variant="Input" color={theme.palette.midGrey[1]}>
              simonchan@gmail.com
            </Typography>
          </ListItemButton>
          <SideMenuItem
            onClick={() => handleClick()}
            icon={<Icon icon="tabler:diamond" />}
            itemText="Subscription"
          />
          <SideMenuItem
            onClick={() => handleClick()}
            icon={""}
            itemText="Sign out"
          />
        </>
      ),
    },
    {
      type: "support",
      subheading: "",
      functions: (
        <>
          <SideMenuItem
            onClick={() => handleClick()}
            icon={
              <Icon icon="streamline:interface-help-question-message-bubble-help-mark-message-query-question-speech" />
            }
            itemText="Support"
          />
          <SideMenuItem
            onClick={() => handleClick()}
            icon={""}
            itemText="What's new"
          />
          <SideMenuItem
            onClick={() => handleClick()}
            icon={""}
            itemText="Send feedback"
          />
        </>
      ),
    },
    {
      type: "policy",
      subheading: "",
      functions: (
        <>
          <SideMenuItem
            onClick={() => handleClick()}
            icon={""}
            itemText="Privacy Policy"
          />
          <SideMenuItem
            onClick={() => handleClick()}
            icon={""}
            itemText="Terms of service"
          />
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: "fit-content", height: "6vh" }} id="topbar-menu">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableElevation
        endIcon={<KeyboardArrowDownIcon sx={{ color: "#FAFAFA" }} />}
        sx={{
          textTransform: "Capitalize",
          display: "flex",
          justifyContent: "center",
          height: "100%",
          "&:hover": { backgroundColor: theme.palette.primary.main },
          borderRadius: 0,
          p: 0,
          m: 0,
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: "1rem",
            fontFamily: "Raleway",
            mr: -1,
          }}
        >
          Menu
        </Typography>
      </Button>
      <Box
        id="close-menu-wrapper"
        sx={{
          display: open ? "flex" : "none",
          height: "100%",
          width: "80vw",
          position: "fixed",
          right: "0",
        }}
        onClick={() => handleClick()}
      >
        <Box
          id="left-menu-wrapper"
          sx={{
            display: open ? "flex" : "none",
            flexDirection: "column",
            height: "100%",
            width: "20vw",
            position: "fixed",
            left: "0",
            top: "6vh",
            backgroundColor: theme.palette.mainBackground,
            overflowY: "scroll",
          }}
          component="nav"
        >
          <DocumentNav>
            {functionList &&
              functionList.map((section, index) => {
                const isLastSection = index === functionList.length - 1;
                return (
                  <Box key={index}>
                    <ListSubheader
                      sx={{
                        backgroundColor: theme.palette.mainBackground,
                      }}
                    >
                      <Typography
                        fontSize="1rem"
                        color={theme.palette.midGrey[2]}
                        fontWeight={500}
                        sx={{}}
                      >
                        {section.subheading}
                      </Typography>
                    </ListSubheader>
                    <Box
                      key={section.type}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      {section.functions}
                    </Box>
                    {!isLastSection && (
                      <Divider
                        variant="middle"
                        sx={{
                          width: "95%",
                          margin: "0.5rem auto",
                        }}
                      />
                    )}
                  </Box>
                );
              })}
          </DocumentNav>
        </Box>
      </Box>
    </Box>
  );
};
export default SideMenu;
