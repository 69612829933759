import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import API from "../../Utils/API";
import Logger from "../../Components/Logger";
import { useTranslation } from "react-i18next";
import theme from "../../theme/theme";
import { Box, Typography, styled, Button, ThemeProvider } from "@mui/material";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { StyledInputBox } from "./styles";
import "./login.css";

import { db } from "../../Firebase";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";

const StyledTypography = styled(Typography)(({ theme }) => ({
  ml: 1,
  fontSize: "1.1em",
  fontFamily: "Raleway",
  fontWeight: 500,
  color: theme.palette.secondary.main,
}));

const logger = new Logger(5);

const provider = new GoogleAuthProvider();

const LoginComponent = ({ handleSwitchToSignUp }) => {
  const navigate = useNavigate();
  const [authFail, setAuthFail] = useState(false);
  const { register, handleSubmit } = useForm();
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [rememberMe, setRememberMe] = useState(true);

  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    logger.logInfo(5, "FormData: " + e.target.value, formData);
  };

  const onSubmit = async (data) => {
    const { username, password } = formData;
    // console.table([{ username: username, pw: password }]);

    try {
      if (!username) {
        document.getElementsByName("username")[0].focus();
        throw new Error("Empty Username");
      }
      if (!password) {
        document.getElementsByName("password")[0].focus();
        throw new Error("Empty Password");
      }
      if (rememberMe) {
        setPersistence(getAuth(), browserLocalPersistence)
          .then(() => {
            console.log("setPersistence session");
          })
          .catch((error) => {
            logger.logInfo(2, error.code, error.message);
          });
      } else {
        setPersistence(getAuth(), browserSessionPersistence)
          .then(() => {
            console.log("setPersistence memory");
          })
          .catch((error) => {
            logger.logInfo(2, error.code, error.message);
          });
      }

      const isEmail = /\S+@\S+\.\S+/.test(username);

      let credential;

      if (isEmail) {
        credential = await signInWithEmailAndPassword(
          getAuth(),
          username,
          password
        );
      } else {
        const snapshot = await db
          .collection("users")
          .where("username", "==", username)
          .get();
        // .then((qs) => {
        //   qs.forEach((doc) => {
        //     console.log(doc.data());
        //   });
        // });
        logger.logInfo(5, "snapshot ", snapshot);
        // logger.logInfo(5, "snapshot email: ", snapshot.docs[0].data().email)
        if (!snapshot.empty) {
          const email = snapshot.docs[0].data().email.trim();
          credential = await signInWithEmailAndPassword(
            getAuth(),
            email,
            password
          );
        } else {
          setAuthFail(true);
          // logger.logInfo(3,"No such User");
          throw new Error("User not found");
        }
      }

      const idToken = await credential.user.getIdToken();
      API.setCookie("JWTtoken", idToken, "/", "/", 180000);

      setAuthFail(false);
      navigate("/portal/home");
    } catch (error) {
      logger.logInfo(2, error.code, error.message);
      setAuthFail(true);
      if (error.code === "auth/invalid-email") {
        // Extract the email from the error message
        const email = error.message.match(/"(.*?)"/)[1];
        console.log(email);
      }
    }
  };

  const onError = (errors, e) => {
    logger.logInfo(2, errors.message);
    // console.log(errors)
  };

  const handleSignInWithGoogle = async () => {
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // const user = result.user;
      // console.log(user);

      API.setCookie("JWTtoken", token, "/", "/", 180000);

      navigate("/portal/home");
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      logger.logInfo(2, errorCode, errorMessage, email, credential);
      throw error;
    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#0C5E9B",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      {/* <Box sx={{position: "absolute", top: "4.47vh", left: "7%", width: "84%", height:"9.7%"}}> */}
      <StyledInputBox>
        {/* <TextField {...register("username", { required: true })} varient = "Outlined" sx={{width: "100%", bgcolor: "white", borderRadius: "1.79vh",border:0,height:"5vh"}} label="username"  size="small"></TextField> */}
        <input
          {...register("username")}
          type="text"
          name="username"
          placeholder="Email"
          className="loginInput"
          onChange={handleInputChange}
          // ref={emailElement}
        ></input>
      </StyledInputBox>
      {/* <Box sx={{position: "absolute", top: "13vh", left: "7%", width: "84%", height:"9.7%"}}> */}
      <StyledInputBox sx={{ mt: 1.5 }}>
        {/* <TextField {...register("password", { required: true })} varient = "Outlined" sx={{width: "100%", bgcolor: "white", borderRadius: "1.79vh",border:0,height:"100%"}} label="password"   size="normal"></TextField> */}
        <input
          {...register("password")}
          type="password"
          name="password"
          placeholder="Password"
          className="loginInput"
          onChange={handleInputChange}
          // ref={passwordElement}
        ></input>
      </StyledInputBox>
      {authFail && (
        <Box
          sx={{
            marginTop: "1vh",
            alignSelf: "flex-start",
            pl: "calc(8% + 7px)",
            fontFamily: "Raleway",
            color: "#EB5757",
            fontSize: "1em",
            fontWeight: "600",
          }}
        >
          Login Failed
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "84%",
          height: "2%",
          mt: 1,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          spacing={1}
          sx={{ mt: 2 }}
        >
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            checked={rememberMe}
            onChange={handleRememberMe}
            sx={{ mr: 1, ml: 1 }}
          />
          <StyledTypography variant="span">Remember me</StyledTypography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <StyledTypography
            variant="span"
            sx={{
              pr: "7px",
              color: "#C4C4C4",
            }}
          >
            Forget password
          </StyledTypography>
        </Box>
      </Box>

      <Box sx={{ width: "84%", m: 1, p: 1 }}>
        <Button
          sx={{ width: "100%", height: "5.36vh", mb: "14px" }}
          variant="pill"
          color="primary"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Log in
        </Button>
        <Button
          sx={{ width: "100%", height: "5.36vh" }}
          variant="pill"
          color="secondary"
          onClick={handleSignInWithGoogle}
        >
          <StyledTypography variant="span">
            Sign in with Google
          </StyledTypography>
        </Button>
        <Divider sx={{ mt: 1.5, mb: 1.5 }} />
        <Button
          sx={{ width: "100%", height: "5.36vh" }}
          variant="pill"
          color="secondary"
          onClick={handleSwitchToSignUp}
        >
          <StyledTypography variant="span">Open new account</StyledTypography>
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default LoginComponent;
