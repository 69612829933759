import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react"; // Assuming you have installed the @tinymce/tinymce-react package
import config from "../config.json";

function TinyEditorComponentTermsSuggestion(props) {
  const [selectedParagraph, setSelectedParagraph] = useState(null);
  useEffect(() => {
    if (!props.selectedClause) {
      return;
    }
    var startIndex = props.selectedClause.start;
    var endIndex = props.selectedClause.end;
    let currentClause = props.selectedClause.answer;
    const editor = window.tinymce.activeEditor;
    const content = editor.getContent();

    const updatedHtmlContent = content.replace(
      /<span class="highlight"[^>]*>(.*?)<\/span>/g,
      (match, p1) => p1
    );

    startIndex = updatedHtmlContent.indexOf(currentClause);
    endIndex = startIndex + currentClause.length;
    console.table({
      updatedHtmlContent: updatedHtmlContent,
      startIndex: startIndex,
      endIndex: endIndex,
      currentClause: currentClause,
      offset: currentClause.length,
    });
    const highlightedText = `<span class="highlight" id="term_ex">${updatedHtmlContent.substring(
      startIndex,
      endIndex
    )}</span>`;
    const updatedContent =
      updatedHtmlContent.substring(0, startIndex) +
      highlightedText +
      updatedHtmlContent.substring(endIndex);
    editor.setContent(updatedContent);
    const highlightStyle = `<style>.highlight { background-color: rgba(177, 213, 255, 0.3); }</style>`;

    editor.getDoc().head.innerHTML += highlightStyle;
    console.table({
      startIndex: startIndex,
      endIndex: endIndex,
      highlightedText: highlightedText,
      content: content,
      updatedContent: updatedContent,
    });
  }, [props.selectedClause]);

  const handleContentUpdate = (newValue) => {
    props.updateContent(newValue);
    console.log(`Content updated: ${newValue}`);
  };

  return (
    <Editor
      value={props.content}
      apiKey={config.tinyApi.apiKey}
      style={{ border: "none" }}
      onEditorChange={(newValue, editor) => {
        handleContentUpdate(newValue);
        // console.log(props.content);
      }}
      init={{
        selector: "#textarea",
        height: "100%",
        resize: false,
        // plugins: [
        //   "advlist autolink lists link image charmap print preview anchor",
        //   "searchreplace visualblocks code fullscreen",
        //   "insertdatetime media table paste code help wordcount",
        // ],
        convert_newlines_to_brs: true,
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        valid_elements: "*[*]",
        statusbar: false,
      }}
    />
  );
}

export default TinyEditorComponentTermsSuggestion;
