import { Box, Fab, SvgIcon, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import theme from "../theme/theme";
import { Icon } from "@iconify/react";
import "../App.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const EditIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
				<path
					d="M2 20C1.45 20 0.979002 19.804 0.587002 19.412C0.195002 19.02 -0.000664969 18.5493 1.69779e-06 18V2C1.69779e-06 1.45 0.196001 0.979002 0.588001 0.587002C0.980001 0.195002 1.45067 -0.000664969 2 1.69779e-06H10L16 6V10H14V7H9V2H2V18H8V20H2ZM14.3 12.525L15.375 13.6L11.5 17.45V18.5H12.55L16.425 14.65L17.475 15.7L13.175 20H10V16.825L14.3 12.525ZM17.475 15.7L14.3 12.525L15.75 11.075C15.9333 10.8917 16.1667 10.8 16.45 10.8C16.7333 10.8 16.9667 10.8917 17.15 11.075L18.925 12.85C19.1083 13.0333 19.2 13.2667 19.2 13.55C19.2 13.8333 19.1083 14.0667 18.925 14.25L17.475 15.7Z"
					fill={props.fill}
				/>
			</svg>
		</SvgIcon>
	);
};

const TrafficLight = () => {
	//Variable declarations
	const location = useLocation();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState("review");
	const [id, setId] = useState("");

	//Run when page is loaded
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const id = searchParams.get("id");
		const currentPage = location.pathname.split("/").pop();
		setCurrentPage(currentPage);
		setId(id);
		// console.table([{ id: id, currentPage: currentPage }]);
	}, []);

	//Styles
	const fabStyles = {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		borderRadius: "30px",
		boxShadow: 1,
		p: 1,
	};

	//Helper functions
	const navigateToPage = (page) => {
		console.log("Page Value is ", page);
		if (!page || page == "") {
			return;
		}
		const path = `/portal/${page}?id=${id}`;
		navigate(path);
	};

	return (
		<ThemeProvider theme={theme}>
			<Box
				display={"flex"}
				flexDirection={"column"}
				position={"fixed"}
				bottom={40}
				right={10}
				p={2}
				gap={1}
				bgcolor={"rgba(234, 234, 234, 0.5)"}
				borderRadius={"37px"}
				width={"5%"}>
				<Fab
					sx={{
						...fabStyles,
						bgcolor: currentPage === "review" ? "#F8D377" : "#FDF6E0",
						":hover":{
							bgcolor: currentPage === "review" ? "#F8D377" : "#FDF6E0", boxShadow: 4,
						}
					}}
					onClick={() => navigateToPage("draft")}>
					<EditIcon fill={currentPage === "draft" ? "#FFF" : "#F9D378"} />
					<Typography variant="ButtonText"> Edit</Typography>
				</Fab>
				<Fab
					sx={{
						...fabStyles,
						bgcolor: currentPage === "Correction" ? "#DD5471" : "#F8E6E4",
						":hover":{
							bgcolor: currentPage === "Correction" ? "#DD5471" : "#F8E6E4", boxShadow: 4,
						}
					}}
					onClick={() => navigateToPage("Correction")}>
					<SvgIcon>
						<Icon
							icon="icon-park-solid:check-correct"
							color={currentPage === "Correction" ? "#FFF" : "#DD5471"}
						/>
					</SvgIcon>
					<Typography variant="ButtonText"> Correction</Typography>
				</Fab>
				<Fab
					sx={{
						...fabStyles,
						bgcolor: currentPage === "terms-explanation" ? "#325378" : "#DEEBF5",
						":hover":{
							bgcolor: currentPage === "terms-explanation" ? "#325378" : "#DEEBF5", boxShadow: 4,
						}
					}}
					onClick={() => navigateToPage("terms-explanation")}>
					<ReviewsOutlinedIcon
						sx={{
							color: currentPage === "terms-explanation" ? "#FFF" : "#325478",
						}}
					/>

					<Typography
						variant="ButtonTextSmall"
						sx={{
							color: currentPage === "terms-explanation" ? "#FFF" : "#525252",
						}}>
						Term Explanation
					</Typography>
				</Fab>
				<Fab
					sx={{
						...fabStyles,
						bgcolor: currentPage === "abstract" ? "#62D4A2" : "#E2F3E9",
						":hover":{
							bgcolor: currentPage === "abstract" ? "#62D4A2" : "#E2F3E9", boxShadow: 4,
						}
						
					}}
					onClick={() => navigateToPage("abstract")}>
					<Icon
						icon="icon-park-outline:compression"
						width={24}
						color={currentPage === "abstract" ? "#FFF" : "#62D4A2"}
					/>
					<Typography variant="ButtonText"> Abstract</Typography>
				</Fab>
			</Box>
		</ThemeProvider>
	);
};

export default TrafficLight;
