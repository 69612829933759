import styled from 'styled-components'

import i18n from './localizer'
import { useTranslation } from 'react-i18next'

const Title = styled.h1`
  margin: 20px;
`

function Support() {
  return (
    <div>
      <Title>Support</Title>
    </div>
  )
}

export default Support