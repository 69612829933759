import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Box } from "@mui/material";
// image
import bgImage from "../../Image/404_background.png";
import logo from "../../Image/logo_full.png";
// css
import "./page404.css";

// ----------------------------------------------------------------------

const StyledContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#dddddd",
  backgroundImage: `url(${bgImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  backgroundSize: "contain",
  height: "100vh",
  margin: "0",
}));

const StyledContent = styled("div")(({ theme }) => ({
  width: "50%",
  height: "100vh",
  display: "flex",
  alignContent: "left",
  flexDirection: "column",
  padding: "28vh 0 0 7vh",
  boxSizing: "border-box",
  color: "#0C5E9B",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: "136px",
  borderRadius: "25px",
  fontSize: "1rem",
  lineHeight: "2rem",
  fontFamily: "Raleway",
  backgroundColor: "#2C82C1",
  "&:hover": {
    backgroundColor: "#0C5E9B",
  },
  textTransform: "none",
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>404 Page Not Found</title>
        </Helmet>
      </HelmetProvider>

      <StyledContainer>
        <Box
          sx={{
            position: "absolute",
            left: "7vh",
            top: "4.5vh",
            width: "17.8vh",
            height: "3.49vh",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "100%", width: "100%" }}
          ></img>
        </Box>
        <StyledContent>
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontSize: "2.2rem",
              fontWeight: "600",
              lineHeight: "3.7rem",
              letterSpacing: "0em",
            }}
          >
            404 Page not find
          </Typography>

          <Typography
            sx={{
              fontFamily: "Raleway",
              fontSize: "1.2rem",
              fontWeight: "500",
              lineHeight: "1.7rem",
              letterSpacing: "0em",
              mb: "1rem",
            }}
          >
            Something is wrong
          </Typography>

          <StyledButton to="/" variant="contained" component={RouterLink}>
            Home
          </StyledButton>
        </StyledContent>
      </StyledContainer>
    </>
  );
}
