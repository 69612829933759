import { doc, writeBatch } from "firebase/firestore";
import React from "react";
import { db } from "../Firebase";

const ProjectContext = React.createContext({
  selectedProject: "",
  projectList: [],
  setProjectList: () => {},
  onSelectProject: () => {},
  createNewProject: () => {},
});
export const ProjectProvider = ({ children }) => {
  const [selectedProject, setSelectedProject] = React.useState("");
  const [projectList, setProjectList] = React.useState([]);

  //Handlers
  const handleSelectProject = (projectName) => {
    //Error Case => Early Return
    if (projectName === null || projectName === "") {
      return;
    }

    //set selected project after checking
    if (projectName === selectedProject) {
      setSelectedProject("");
    } else {
      setSelectedProject(projectName);
    }
  };

  const createNewProject = async (projectName, projectTags, documents) => {
    if (!projectName || !projectTags || !documents) {
      return;
    }
    const batch = writeBatch(db);
    try {
      documents.forEach((document) => {
        const existingProjects = document.projects || [];
        const existingTags = document.tags || [];
        const documentRef = doc(db, "files", document.id);
        batch.update(documentRef, {
          projects: [...new Set([...existingProjects, projectName])],
          tags: [...new Set([...existingTags, ...projectTags])],
        });
      });
      setProjectList([...projectList, projectName]);
      await batch.commit();
    } catch (error) {
      console.error("Error creating new project:", error);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        selectedProject: selectedProject,
        projectList: projectList,
        onSelectProject: handleSelectProject,
        setProjectList: setProjectList,
        createNewProject: createNewProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
