import React, { useState, useEffect, useContext } from "react";
import { Box, AppBar, Button } from "@mui/material";
import ContracfyLogo from "../../Image/logo.png";
import { NavLink } from "react-router-dom";
import MyMenu from "./menu";
import EditText from "./EditText";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import API from "../../Utils/API";
import DocumentContext from "../../hooks/DocumentContext";
import { LoadingButton } from "@mui/lab";

export default function TopBar() {
  const [title, setTitle] = useState("Empty Title");
  const { documentData, titleWithId } = useContext(DocumentContext);
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    API.fetchDocumentTitle().then((title) => {
      setTitle(title);
    });
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await API.saveContentToFirebase(documentData.value, titleWithId);
      setIsSaving(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppBar
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-around",
        width: "100vw",
        height: "6vh",
        backgroundColor: "#1C2D4D",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Box
          component={NavLink}
          to="/portal/home"
          sx={{ display: "flex", justifyContent: "center", padding: "1rem" }}
        >
          <Box
            component="img"
            src={ContracfyLogo}
            sx={{ width: "15vh", height: "3vh", cursor: "pointer" }}
          />
        </Box>

        <MyMenu />
        <Button className="pasteIcon">
          <ContentPasteSearchIcon
            sx={{ color: "#FAFAFA" }}
          ></ContentPasteSearchIcon>
        </Button>
      </Box>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EditText
          text={title}
          canEdit={true}
          changeText={setTitle}
          style={{ marginLeft: "10px" }}
        ></EditText>
      </Box>

      <Box sx={{ width: "25%", display: "flex", justifyContent: "flex-end" }}>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingButton
            variant="contained"
            loading={isSaving}
            sx={{
              textTransform: "Capitalize",
              color: "#FAFAFA",
              backgroundColor: "#2C82C1",
              mx: 2,
              my: 1,
            }}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
          <Button
            variant="contained"
            sx={{
              textTransform: "Capitalize",
              color: "#FAFAFA",
              backgroundColor: "#2C82C1",
            }}
          >
            Export
          </Button>
        </Box>
      </Box>
    </AppBar>
    // <TopPos position='fix'> Test </TopPos>
  );
}
