import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useState, createContext } from "react";
import { db } from "../Firebase";
import { writeBatch, doc } from "firebase/firestore";

/**
 * useContext Hook for Tag Function
 *
 *
 */
const TagContext = createContext();

export const TagProvider = ({ children }) => {
  const [selectedTag, setSelectedTag] = useState("All document");
  const [openAddTag, setOpenAddTag] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [tagSnackBarOpen, setTagSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severity, setSeverity] = useState("info");

  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
  };

  const handleOpenAddTag = () => {
    setOpenAddTag(true);
  };

  const handleCloseAddTag = () => {
    setOpenAddTag(false);
  };

  /**
   * Add a tag to tag list
   *
   * @param {string} tagName
   */
  const handleAddTag = async (tagName, selectedDoc) => {
    if (!tagList.includes(tagName)) {
      setTagList([...tagList, tagName]);
      if (!selectedDoc || selectedDoc.length <= 0) {
        return;
      } else {
        var warningFlag = false;
        const batch = writeBatch(db);
        selectedDoc?.forEach((document) => {
          const documentRef = doc(db, "files", document.id);
          if (document.tags.includes(tagName)) {
            handleOpenTagSnackbar(
              `Document "${document.title}" Already Has the tag "${tagName}"!`,
              "warning"
            );
            warningFlag = true;
            return;
          }
          document.tags = [...document.tags, tagName];
          batch.update(documentRef, { tags: document.tags });
        });
        if (!warningFlag) {
          batch
            .commit()
            .then(() => {
              handleOpenTagSnackbar(
                `Added the tag "${tagName}" to the Documents!`,
                "success"
              );
            })
            .catch((error) => {
              console.error("Error updating documents:", error);
            });
        }
      }
      setOpenAddTag(false);
    } else {
      handleOpenTagSnackbar(`The Tag "${tagName}" Already Exists!`, "warning");
    }
  };

  /**
   * Handles the snackbar for visual hints
   *
   * @param {string} message -> message to be displayed
   * @param {string} severity -> color of the snackbar(info, success, warning,error)
   */
  const handleOpenTagSnackbar = (message, severity) => {
    setSnackBarMessage(message);
    setSeverity(severity);
    setTagSnackBarOpen(true);
  };

  const handleCloseTagSnackbar = () => {
    setTagSnackBarOpen(false);
    setSnackBarMessage("");
  };

  const TagSnackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={tagSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => {
          handleCloseTagSnackbar();
        }}
      >
        <Alert severity={severity} sx={{ width: "100%" }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <TagContext.Provider
      value={{
        selectedTag,
        setSelectedTag,
        openAddTag,
        setOpenAddTag,
        tagList,
        setTagList,
        handleTagSelect,
        handleOpenAddTag,
        handleCloseAddTag,
        handleAddTag,
        handleOpenTagSnackbar,
      }}
    >
      <TagSnackBar />
      {children}
    </TagContext.Provider>
  );
};
export default TagContext;
