import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import theme from "../theme/theme";
import { ThemeProvider } from "@mui/material";
import companyLogo from "../Image/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  AddOutlined,
  BookmarkAddOutlined,
  BookmarkRemove,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  Folder,
  LiveHelpOutlined,
  LocalOffer,
  LogoutOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../Firebase";
import { getAuth } from "firebase/auth";

import PopupDialog from "./PopupDialog";

import API from "../Utils/API";
import CustomEllipsis from "../Utils/CustomEllipsis";
import TagContext from "../hooks/TagContext";
import ProjectContext from "../hooks/ProjectContext";
import FirestoreContext from "../hooks/FirestoreContext";

const LeftNav = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [hoveredProject, setHoveredProject] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tagToDelete, setTagToDelete] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddProjectDialog, setOpenAddProjectDialog] = useState(false);

  const [tagToEdit, setTagToEdit] = useState("");
  const navigate = useNavigate();
  const {
    tagList,
    handleTagSelect,
    setTagList,
    selectedTag,
    setSelectedTag,
    handleOpenTagSnackbar,
  } = useContext(TagContext);
  const firestoreData = useContext(FirestoreContext);

  const projectContext = useContext(ProjectContext);

  const handleLogoutOpen = () => {
    setLogoutOpen(!logoutOpen);
  };
  const handleOpenAddProject = () => {
    setOpenAddProjectDialog(true);
  };
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    // console.log("Expand");
  };
  const handleNavigation = (location) => {
    const url = `/portal/${location}`;
    navigate(url);
  };
  const handleLogout = async () => {
    //handle Logout here
    await API.signout();
    const url = `/login`;
    navigate(url);
  };

  const handleEditTag = async (
    originalTag,
    newTag,
    updatedTags,
    setUpdatedTags
  ) => {
    await editTagForAllDocuments(
      originalTag,
      newTag,
      setTagList,
      tagList,
      setSelectedTag,
      updatedTags,
      setUpdatedTags
    );
    handleOpenTagSnackbar(`Successfully edited ${originalTag}`, "success");
    setOpenEditDialog(false);
  };

  const editTagForAllDocuments = async (
    originalTag,
    newTag,
    setTagList,
    tagList,
    setSelectedTag,
    updatedTags,
    setUpdatedTags
  ) => {
    const batch = writeBatch(db);
    const userId = getAuth().currentUser.uid;
    const documentListQuery = query(
      collection(db, "files"),
      where("tags", "array-contains", originalTag),
      where("userId", "==", userId)
    );

    const documentList = await getDocs(documentListQuery);
    documentList.forEach((doc) => {
      const docRef = doc.ref;
      const docData = doc.data();
      const _tagsToUpdate = [...docData.tags];
      const targetIndex = _tagsToUpdate.indexOf(originalTag);

      if (targetIndex !== -1) {
        let updatedTagsArray = [];
        _tagsToUpdate.forEach((tag, index) => {
          if (index !== targetIndex) {
            updatedTagsArray = [...updatedTagsArray, tag];
          } else {
            updatedTagsArray = [...updatedTagsArray, newTag];
          }
        });

        batch.update(docRef, { tags: updatedTagsArray });
      }
    });
    try {
      await batch.commit();
      const _tagList = [...tagList];
      const targetIndex = _tagList.indexOf(originalTag);
      if (targetIndex !== -1) {
        let updatedTagList = [];
        _tagList.forEach((tag, index) => {
          if (index !== targetIndex) {
            updatedTagList = [...updatedTagList, tag];
          } else {
            updatedTagList = [...updatedTagList, newTag];
          }
        });
        setTagList(updatedTagList);
      }
      setSelectedTag("All document");
    } catch (error) {
      console.log("Error updating documents: ", error);
    }
  };

  const handleDeleteTag = async (newTag) => {
    await API.deleteTagFromDocuments(
      newTag,
      setTagList,
      tagList,
      setSelectedTag
    );
    handleOpenTagSnackbar(`Successfully deleted ${newTag}`, "success");
    setOpenDeleteDialog(false);
  };

  const DeleteTagDialog = () => {
    const dialogActions = (
      <>
        <Button
          variant="pill"
          color="secondary"
          onClick={() => {
            setOpenDeleteDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="pill"
          color="primary"
          onClick={() => handleDeleteTag(tagToDelete)}
        >
          Confirm
        </Button>
      </>
    );
    return (
      <PopupDialog
        open={openDeleteDialog}
        dialogTitle="Confirm to Delete"
        dialogActions={dialogActions}
        close={() => {
          setOpenDeleteDialog(false);
        }}
        dialogIcon={<BookmarkRemove />}
        dialogContent={<Typography>{tagToDelete}</Typography>}
      />
    );
  };

  const EditTagDialog = () => {
    const [updatedTags, setUpdatedTags] = useState([]);
    const [tagName, setTagName] = useState(tagToEdit);
    const originalTag = tagToEdit;
    const dialogActions = (
      <>
        <Button
          variant="pill"
          color="secondary"
          onClick={() => {
            setOpenEditDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="pill"
          color="primary"
          onClick={
            () =>
              handleEditTag(originalTag, tagName, updatedTags, setUpdatedTags) //dev
          }
        >
          Confirm
        </Button>
      </>
    );
    const dialogContent = (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <TextField
          variant="outlined"
          placeholder="New Tag Name"
          value={tagName}
          onChange={(e) => {
            setTagName(e.target.value);
          }}
          size="small"
          sx={{ width: "100%" }}
        />
        {tagName === "" && (
          <Typography variant="InText" color={theme.palette.status.error}>
            Tag Name Must not Be Empty
          </Typography>
        )}
      </Box>
    );
    return (
      <PopupDialog
        open={openEditDialog}
        dialogTitle="Edit Tag"
        dialogActions={dialogActions}
        close={() => {
          setOpenEditDialog(false);
        }}
        dialogIcon={<BookmarkAddOutlined />}
        dialogContent={dialogContent}
      />
    );
  };

  const AddProjectDialog = () => {
    const [projectName, setProjectName] = useState("");
    const projectAreaRef = useRef("");
    const [projectDocument, setProjectDocument] = useState([]);

    const _projectArea = [
      "Banking and Finance",
      "Cpaital Market",
      "China Trade and Investment",
      "Competition",
      "Construction",
      "Corporate and M&A",
      "Corporate Services",
      "Data Protection and Privacy",
      "Employment and Pensions",
    ];
    const projectAreaOptions = _projectArea.map((area) => ({
      value: area,
      label: area,
    }));
    const documentListOptions = firestoreData.documentList?.map((document) => ({
      value: document,
      label: document.fileName.split("_").slice(1).join("_"),
    }));

    const handleConfirmClick = () => {
      projectContext.createNewProject(
        projectName,
        projectAreaRef.current,
        projectDocument
      );
      setOpenAddProjectDialog(false);
    };
    const handleCancelClick = () => {
      setOpenAddProjectDialog(false);
    };
    const dialogContent = (
      <Box
        id="add-project-content"
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        <FormControl id="form-add-project-name" sx={{ m: 1, width: "50vw" }}>
          <Typography
            variant="InText"
            color="typography.1"
            sx={{ mb: 1 }}
          >{`Project Name`}</Typography>
          <TextField
            autoComplete="off"
            name="project-name"
            label="Project Name"
            fullWidth
            onChange={(e) => setProjectName(e.target.value)}
          />
        </FormControl>
        <FormControl id="form-add-project-type" sx={{ m: 1, width: "50vw" }}>
          <Typography
            variant="InText"
            color="typography.1"
            sx={{ mb: 1 }}
          >{`Project Area`}</Typography>
          <Autocomplete
            multiple={true}
            options={projectAreaOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Project Area" variant="outlined" />
            )}
            onChange={(e, newValue) => {
              const selectedLabels = newValue.map((option) => option.label);
              projectAreaRef.current = selectedLabels;
            }}
          />
        </FormControl>
        <FormControl
          id="form-add-project-document"
          sx={{ m: 1, width: "50vw" }}
        >
          <Typography
            variant="InText"
            color="typography.1"
            sx={{ mb: 1 }}
          >{`Project Documents`}</Typography>
          <Autocomplete
            multiple={true}
            options={documentListOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project Documents"
                variant="outlined"
              />
            )}
            onChange={(e, newValue) => {
              const selectedLabels = newValue.map((option) => option.value);
              setProjectDocument(selectedLabels);
            }}
          />
        </FormControl>
      </Box>
    );

    const dialogActions = (
      <Box
        id="add-project-actions"
        sx={{
          display: "flex",
          alignContent: "center",
          gap: 2,
          justifyContent: "center",
        }}
      >
        <Button onClick={handleCancelClick} variant="pill" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClick}
          variant={"pill"}
          color={"primary"}
          className={projectName && projectDocument.length > 0 ? "" : "disable"}
        >
          Confirm
        </Button>
      </Box>
    );
    return (
      <PopupDialog
        open={openAddProjectDialog}
        dialogTitle="New Project"
        dialogActions={dialogActions}
        close={() => {
          setOpenAddProjectDialog(false);
        }}
        dialogContent={dialogContent}
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="left-nav"
        sx={{
          color: "white",
          display: "flex",
          position: "fixed",
          backgroundColor: theme.palette.darkBlue,
          height: "98%",
          borderRadius: 5,
          boxSizing: "border-box",
          padding: 2,
          width: "25%",
        }}
      >
        <Box
          className="bodyWrapper"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            id="logoWrapper"
            height={"fit-content"}
            style={{
              borderBottom: `1px solid #475468`,
              padding: 10,
              paddingTop: 18,
              paddingLeft: 18,
            }}
          >
            <img
              src={companyLogo}
              alt="logo"
              style={{ width: "62%", height: "auto" }}
            ></img>
          </Box>
          <Box
            className="function-wrapper"
            sx={{
              height: "90%",
              overflowX: "hidden",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box
              id="upper-section"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                id="MainFunctionWrapper"
                sx={{
                  paddingTop: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Button
                  id="toHomepage"
                  variant="leftNav"
                  onClick={() => {
                    handleNavigation("home");
                  }}
                >
                  <HomeIcon />
                  <Typography variant="SubTitle" marginLeft={2}>
                    Homepage
                  </Typography>
                </Button>
                <Button
                  id="toAccount"
                  variant="leftNav"
                  onClick={() => {
                    handleNavigation("home/user");
                  }}
                >
                  <AccountCircleOutlinedIcon />
                  <Typography variant="SubTitle" marginLeft={2}>
                    Account
                  </Typography>
                </Button>
              </Box>
              {/* Projects */}
              <Box id="project-container">
                <List disablePadding>
                  <Box
                    id="project-section-name"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    padding={2}
                    paddingBottom={0}
                    paddingTop={8}
                  >
                    <Typography variant="SubTitle" textAlign={"center"}>
                      Project
                    </Typography>
                    <Avatar
                      onClick={handleOpenAddProject}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: theme.palette.primary.main,
                      }}
                    >
                      <AddOutlined sx={{ color: "#FAFAFA" }} />
                    </Avatar>
                  </Box>
                  {/* Project Buttons */}
                  {projectContext.projectList
                    ?.slice(0, isExpanded ? tagList.length : 4)
                    .map((project, index) => (
                      <ListItemButton
                        key={index}
                        sx={{
                          borderRadius: 2,
                          height: "3.5rem",
                          backgroundColor:
                            projectContext.selectedProject === project
                              ? theme.palette.primary.main
                              : "transparent",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                          },
                        }}
                        onClick={() => {
                          projectContext.onSelectProject(project);
                        }}
                        onMouseEnter={(e) => {
                          setHoveredProject(e.target.textContent);
                        }}
                        onMouseLeave={(e) => {
                          setHoveredProject("");
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: "1.5rem" }}>
                          <Folder sx={{ color: "#C4C4C4" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <CustomEllipsis text={project} maxLength={35} />
                          }
                          sx={{
                            marginLeft: 2,
                            "& .MuiTypography-root": {
                              color: theme.palette.lightGrey[3],
                              maxWidth: "90%",
                              height: "inherit",
                            },
                          }}
                        />
                        {/* {hoveredTag ===
                          CustomEllipsis({ text: tag, maxLength: 35 }) && (
                          <Box display="flex" justifyContent="flex-end">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setTagToEdit(tag);
                                setOpenEditDialog(true);
                              }}
                              sx={{ color: "#FFF" }}
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setTagToDelete(tag);
                                setOpenDeleteDialog(true);
                              }}
                              sx={{ color: "#FFF" }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        )} */}
                      </ListItemButton>
                    ))}
                  {projectContext.projectList.length > 4 && (
                    <ListItemButton
                      sx={{
                        backgroundColor: "#0E1C35",
                        borderRadius: 5,
                      }}
                      onClick={handleExpand}
                    >
                      <ListItemIcon
                        sx={{ color: "#C4C4C4", minWidth: "2.5rem" }}
                      >
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                      <ListItemText sx={{ color: "#C4C4C4" }}>
                        {isExpanded ? "less" : "more"}
                      </ListItemText>
                    </ListItemButton>
                  )}
                </List>
              </Box>
            </Box>
            <Box
              id="signOut-support-wrapper"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Button
                id="toSupport"
                variant="leftNav"
                onClick={() => {
                  handleNavigation("support");
                }}
              >
                <LiveHelpOutlined />
                <Typography variant="SubTitle" marginLeft={2}>
                  Support
                </Typography>
              </Button>
              <Button
                id="toSignout"
                variant="leftNav"
                onClick={handleLogoutOpen}
              >
                <LogoutOutlined />
                <Typography variant="SubTitle" marginLeft={2}>
                  Sign Out
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog open={logoutOpen} onClose={handleLogoutOpen}>
        <DialogTitle>
          <Typography
            variant="Title"
            sx={{ color: theme.palette.primary.main }}
          >
            Do you want to sign out?
          </Typography>
        </DialogTitle>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleLogout}>Sign Out</Button>
          <Button onClick={handleLogoutOpen}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <AddProjectDialog />
      <DeleteTagDialog />
      <EditTagDialog />
    </ThemeProvider>
  );
};

export default LeftNav;
