import { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import config from "../config.json";

export default function TinyEditorComponentClassic(props) {
  const handleContentUpdate = (newValue) => {
    props.updateContent(newValue);
  };

  return (
    <Editor
      value={props.content}
      apiKey={config.tinyApi.apiKey}
      style={{ border: "none" }}
      onEditorChange={(newValue, editor) => {
        handleContentUpdate(newValue);
      }}
      init={{
        selector: "textarea",
        height: "100%",
        resize: false,
        // plugins: [
        //   "advlist autolink lists link image charmap print preview anchor",
        //   "searchreplace visualblocks code fullscreen",
        //   "insertdatetime media table paste code help wordcount",
        // ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        statusbar: false,
        force_br_newlines: false,
      }}
    />
  );
}
