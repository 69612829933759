import React from 'react';
import Typography from '@mui/material/Typography';

function CustomEllipsis({ text, maxLength }) {
    const displayLength = Math.floor((maxLength - 3) / 2);
    if (text.length > maxLength) {
        text = text.substring(0, displayLength) + "..." + text.substring(text.length - displayLength);
      }
    
      return text;
    }

export default CustomEllipsis;