/*
Firebase initilaization file
Created 29/3 by Leo Yuen

*/

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//Firebase configur`s
const firebaseConfig = {
  apiKey: "AIzaSyB6UVZ6Tf0ayBJm_FsgK6f9yCO-w0JDvUE",
  authDomain: "contracfy-8ed6c.firebaseapp.com",
  projectId: "contracfy-8ed6c",
  storageBucket: "contracfy-8ed6c.appspot.com",
  messagingSenderId: "801787896232",
  appId: "1:801787896232:web:8315bd5ae58954cd5a3d9d",
  measurementId: "G-7DLGSFCKBD",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
