import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

function EditText(props) {
  const witdthRef = useRef(null);
  const [showText, setShowText] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [inputWidth, setInputWidth] = useState(200);
  const minTitleWidth = 200;
  const maxTitleWidth = 400;
  useEffect(() => {
    setShowText(props.text);
  }, [props.text]);

  useLayoutEffect(() => {
    dealInputWidth();
  }, [showText]);

  const dealInputWidth = () => {
    const offsetWidth = witdthRef?.current?.offsetWidth || minTitleWidth;
    // console.log("offset:" + offsetWidth)
    const width = offsetWidth < maxTitleWidth ? offsetWidth + 5 : maxTitleWidth;
    // console.log("width:" + width)
    setInputWidth(width);
  };
  const titleFocus = () => {
    setIsFocus(true);
  };
  const titleInput = (e) => {
    const newTitle = e.target.value;
    setShowText(newTitle);
  };
  const titleBlur = () => {
    const newTitle = showText || "Empty";
    const oldTitle = props.text;
    setIsFocus(false);
    if (showText !== oldTitle) {
      setShowText(newTitle);
      setIsFocus(false);
      if (props?.changeText) {
        props.changeText(newTitle);
      }
    } else {
      setIsFocus(false);
      setShowText(newTitle);
    }
  };
  return (
    <div
      className="wrap"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "2rem",
        width: 500,
      }}
    >
      {props.canEdit ? (
        <input
          value={showText}
          style={{
            width: inputWidth,

            border: 0,
            fontSize: "1rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: "#1C2D4D",
            color: "#FAFAFA",
            borderColor: "#0C5E9B",
            fontFamily: "Raleway",
          }}
          onFocus={titleFocus}
          onChange={titleInput}
          onBlur={titleBlur}
          className="input"
          placeholder="Empty"
        />
      ) : (
        ""
      )}
      {/* 为了计算文字的宽度 */}
      <div style={{ height: 0 }}>
        <div
          style={{
            fontSize: "1rem",
            opacity: 0,
            maxWidth: "100%",
            overflow: "hidden",
            height: 0,
            backgroundColor: "background.paper",
            fontFamily: "Raleway",
            display: "inline-block",
          }}
          ref={witdthRef}
          className={props.canEdit ? "width" : "text"}
        >
          {showText}
        </div>
      </div>
    </div>
  );
}

export default EditText;
