import React, { useEffect, useState } from "react";
import TrafficLight from "../../Components/TrafficLight";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  ThemeProvider,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import theme from "../../theme/theme";
import TopBar from "../../Components/topbar/TopBar";
import API from "../../Utils/API";
import DataAPI from "../../Utils/DataAPI";
import TinyEditorComponentClassic from "../../Components/TinyEditorComponentClassic";
import { LoadingButton } from "@mui/lab";

const Abstract = () => {
  //Varible Initializations
  const [searchTerm, setSearchTerm] = useState("");
  const [content, setContent] = useState("");
  const [QA, setQA] = useState({
    loading: true,
    error: null,
    value: null,
  });
  const [questions, setQuestions] = useState("");
  const [questionPending, setQuestionPending] = useState(false);
  const [isQuestionError, setIsQuestionError] = useState(false);

  //Helper functions
  const fetchDataAndFetchQAAndFetchQuestion = async () => {
    await API.getDocumentTitleAndContent().then((data) => {
      setContent(data.result);
      fetchQA(data.result);
    });
  };

  const fetchQA = async (content) => {
    if (content === "") {
      console.log("Content not found");
      return;
    }
    try {
      const data = await DataAPI.getExtractiveQA(content);
      setQA({ loading: false, error: null, value: data });
    } catch (error) {
      const errorMessage = `Error Getting Q&A from server: ${error}`;
      setQA({ loading: false, error: errorMessage, value: null });
    }
  };

  //On Page Load
  useEffect(() => {
    //Hides ChatBox button
    const chatboxSelector = document.querySelector(".ChatBox");
    if (chatboxSelector) {
      chatboxSelector.style.display = "none";
    }
    fetchDataAndFetchQAAndFetchQuestion();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearch = async () => {
    if (searchTerm === "") {
      return;
    }
    if (QA.value === null || QA.error) {
      setIsQuestionError(true);
      return;
    }
    setQuestionPending(true);
    const data = await DataAPI.getQA(content, searchTerm);
    const result = {
      question: searchTerm,
      answer: data.qa_pairs[0].answer,
      recommended: data.qa_pairs[0],
    };
    const updatedQA = {
      qa_pairs: [result, ...QA.value.qa_pairs],
    };
    setQA((prevState) => {
      return {
        ...prevState,
        value: updatedQA,
      };
    });
    // if (result.recommended) {
    //   console.log(result);
    //   setQuestions(result.recommended);
    // }
    // console.log(questions);
    setQuestionPending(false);
  };
  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const QuestionSnackBar = () => {
    return (
      <Snackbar
        open={isQuestionError}
        autoHideDuration={6000}
        onClose={() => setIsQuestionError(false)}
      >
        <Alert
          onClose={() => setIsQuestionError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error occured while fetching questions!
        </Alert>
      </Snackbar>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <TopBar />
      <TrafficLight />
      <Box
        sx={{
          marginTop: "6vh",
          height: "94vh",
          display: "flex",
        }}
      >
        {/* Left Box */}
        <Box
          width="50%"
          display={"flex"}
          flexDirection={"column"}
          alignContent={"center"}
          bgcolor={"#FFFFFF"}
          borderRight={"1px solid lightgrey"}
          boxShadow={
            ("0 6px 10px 0 rgba(0, 0, 0, 0.14)",
            "0 1px 18px 0 rgba(0, 0, 0, 0.12)",
            " 0 3px 5px -1px rgba(0, 0, 0, 0.4)")
          }
        >
          {/* Known Issue: Setting 100% in height causes overflow in Wrapper */}
          <TinyEditorComponentClassic
            content={content}
            updateContent={handleContentChange}
          />
        </Box>
        {/* Right Box */}
        <Box
          width="50%"
          display={"flex"}
          flexDirection={"column"}
          alignContent={"center"}
          bgcolor={"#FAFAFA"}
          borderLeft={"1px solid lightgrey"}
        >
          <Box
            sx={{
              height: "90px",
              backgroundColor: "#FFF",
              borderBottom: "1px solid lightgrey",
              flexDirection: "column",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <Typography variant="h4" textAlign="center" fontSize="20px">
              Abstract
            </Typography>
            <div style={{ borderBottom: "1px solid lightgrey" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <TextField
                placeholder="Anything you want to know from this contract?"
                value={searchTerm}
                variant="standard"
                onChange={handleSearchChange}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: "12px",
                    textAlign: "center",
                    justifyItems: "center",
                    color: "#959595 ",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ ml: 2 }} />
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                variant="outlined"
                sx={{
                  borderRadius: "30px",
                  color: theme.palette.darkGrey.two,
                  borderColor: theme.palette.green.underline,
                  backgroundColor: theme.palette.highlight.green,
                }}
                onClick={handleSearch}
                loading={questionPending || !QA}
              >
                Search
              </LoadingButton>
            </Box>
          </Box>
          <Box id="QA-wrapper" sx={{ height: "60%", overflow: "scroll" }}>
            {QA.loading && (
              <Box
                className="loading"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {QA.error && <Typography>{QA.error}</Typography>}
            {QA.value !== null &&
              QA.value?.qa_pairs?.map((QA, index) => {
                return (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "16px",
                      backgroundColor: index % 2 === 0 ? "#FAFAFA" : "#FFFFFF",
                      gap: 10,
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#E2F5FF !important",
                      },
                    }}
                    onClick={(e) => {}}
                  >
                    <div
                      style={{ flex: 3, display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="h5">{QA.question}</Typography>
                    </div>
                    <div
                      style={{ flex: 7, display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="h5">{QA.answer}</Typography>
                    </div>
                  </Box>
                );
              })}
          </Box>
          <Box
            id="extra-question-wrapper"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "40%",
              overflowY: "scroll",
              gap: 1,
              padding: 2,
            }}
          >
            <Typography variant="SubTitle" color={theme.palette.midGrey[2]}>
              You may also want to know
            </Typography>
            {!questions && (
              <Box
                className="loading"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography variant="Title">
                  Ask Any Question to Start!
                </Typography>
              </Box>
            )}
            {questions &&
              questions.map((question, index) => {
                return (
                  <Box
                    key={`question${index}`}
                    sx={{
                      width: "80%",
                      backgroundColor: theme.palette.lightGrey[3],
                      borderRadius: "3.125rem",
                      padding: 1,
                      px: 2,
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: theme.palette.lightGrey[2],
                      },
                    }}
                    onClick={(e) => {
                      setSearchTerm(e.target.textContent);
                    }}
                  >
                    <Typography
                      variant="InText"
                      color={theme.palette.midGrey[2]}
                    >
                      {question}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </Box>
        <QuestionSnackBar />
      </Box>
    </ThemeProvider>
  );
};

export default Abstract;
