import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  //Call these Colors with them.palette.[color].[pram]
  //e.g. theme.palette.lightBlue , theme.palette.highlight.blue, theme.palette.typography[1]
  palette: {
    primary: {
      main: "#2C82C1",
    },
    secondary: {
      main: "#0C5E9B",
    },
    lightBlue: "#B6DAF3",
    themeBlue: "#2C82C1",
    secBlue: "#0C5E9B",
    darkBlue: "#1C2D4D",
    darkDarkBlue: "#0E1C35",
    white: "#FFFFFF",
    black: "#000000",
    mainBackground: "#FAFAFA",
    lightGrey: { 2: "#F3F3F3", 3: "#EAEAEA", 4: "#D4D4D4" },
    typography: {
      1: "#333333",
      2: "#525252",
      remark: "#C4C4C4",
    },
    midGrey: {
      1: "#666666",
      2: "#959595",
      3: "#C4C4C4",
    },
    darkGrey: { 2: "#525252" },
    status: {
      correct: "#62D3A2",
      error: "#DD5471",
    },
    features: {
      edit: "#F8D377",
      correction: "#DD5471",
      termExplanation: "#325379",
      abstract: "#62D3A2",
    },
    highlight: {
      blue: "#B1D5FF4D",
      green: "#B6F4CF99",
      red: "#FFB2A899",
      yellow: "#FFCB2199",
    },
    red: {
      primary: "#B7475F",
      highlight: "#DD5471",
    },
    yellow: {
      primary: "#DEB44A",
      highlight: "#F8D377",
      underline: "#F8C218",
    },
    greyBlue: {
      primary: "#96BBD7",
    },
    green: {
      correct: "#62D3A2",
      underline: "#70C892",
      highlight: "#B6F4CF99",
    },
  },

  //Pixel in Figma / 16 = rem in browser
  typography: {
    fontFamily: ["Raleway"].join(","),
    Title: {
      fontFamily: "Raleway",
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "2.375rem",
    },
    SubTitle: {
      fontFamily: "Raleway",
      fontSize: "1.2rem",
      fontWeight: 500,
      lineHeight: "1.75rem",
      "@media (max-width: 1400px)": {
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
    },
    Input: {
      fontFamily: "Raleway",
      fontSize: ".75rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    InText: {
      fontFamily: "Raleway",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    SuggestionCardBodyText: {
      fontFamily: "Raleway",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "2.125rem",
    },
    EditorBodyText: {
      fontFamily: "Raleway",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.1875rem",
    },

    ButtonTextSmall: {
      fontFamily: "Raleway",
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "10px",
      letterSpacing: "0em",
      textAlign: "center",
      color: "#525252",
      textTransform: "none",
    },
    ButtonText: {
      fontFamily: "Raleway",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "19px",
      letterSpacing: "0em",
      textAlign: "center",
      color: "#525252",
      textTransform: "none",
    },
    h4: {
      fontWeight: 400,
      fontSize: "20px",
      fontFamily: "Raleway",
      alignItems: "center",
      color: "#666666",
      margin: "4px",
    },
    h5: {
      fontWeight: 400,
      fontSize: "14px",
      fontFamily: "Raleway",
      textAlign: "justified",
      color: "#666666",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.disable": {
            pointerEvents: "none",
            cursor: "not-allowed",
            color: "white",
            backgroundColor: "#959595",
          },
        },
      },
      variants: [
        {
          props: { variant: "tagButton" },
          style: (props) => ({
            border: "1px solid #D4D4D4",
            borderRadius: "20px",
            maxHeight: "41px",
            maxWidth: "194px",
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontSize: ".75rem",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#737B82",
              color: "#D4D4D4",
              boxShadow: "none",
            },
          }),
        },
        {
          props: { variant: "leftNav" },
          style: (props) => ({
            margin: 1,
            textTransform: "none",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            height: "3.7rem",
            color: "white",
            borderRadius: "1rem",
            "&:hover": { backgroundColor: theme.palette.primary.main },
          }),
        },
        {
          props: { variant: "outlined" },
          style: {
            textTransform: "none",
            borderRadius: "30px",
            border: "1px solid",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
            },
          },
        },
        {
          props: { variant: "contained" },
          style: {
            color: "white",
            textTransform: "none",
            borderRadius: "10px",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
            },
          },
        },
        {
          props: { variant: "pill" },
          style: {
            color: "white",
            textTransform: "none",
            borderRadius: "20px",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
            },
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            backgroundColor: "#2C82C1",
            "&:hover": {
              backgroundColor: "#2C82C1",
            },
            "&.active": {
              backgroundColor: "#0C5E9B",
            },
          },
        },
        {
          props: { variant: "pill", color: "red" },
          style: {
            backgroundColor: "#DD5471",
            "&:hover": {
              backgroundColor: "#DD5471",
            },
            "&.active": {
              backgroundColor: "#B7475F",
            },
          },
        },

        {
          props: { variant: "pill", color: "primary" },
          style: {
            backgroundColor: "#2C82C1",
            "&:hover": {
              backgroundColor: "#2C82C1",
            },
            "&.active": {
              backgroundColor: "#0C5E9B",
            },
          },
        },

        {
          props: { variant: "pill", color: "yellow" },
          style: {
            backgroundColor: "#F8C218",
            "&:hover": {
              backgroundColor: "#F8C218",
            },
            "&.active": {
              backgroundColor: "#DEB44A",
            },
          },
        },
        {
          props: { variant: "pill", color: "secondary" },
          style: {
            backgroundColor: "#FFFFFF",
            border: "1px solid #96BBD7",
            color: "#0C5E9B",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            "&.active": {
              backgroundColor: "#EAEAEA",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            backgroundColor: "#FFFFFF",
            border: "1px solid #96BBD7", // Border color
            color: "#0C5E9B",
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
            "&.active": {
              backgroundColor: "#EAEAEA",
            },
          },
        },
        {
          props: { variant: "search" },
          style: {
            padding: 1,
            border: "1px solid #F8C218", // Border color
            color: "#525252",
            backgroundColor: "#FFFBEE",
            borderRadius: "20px",
            textTransform: "none",
          },
        },
        {
          props: { variant: "skip" },
          style: {
            width: "166px",
            height: "41px",
            border: "1px solid #DD5471", // Border color
            color: "#DD5471",
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
            },
            "&:active": {
              backgroundColor: "#EAEAEA",
            },
          },
        },
      ],
    },
  },
});
theme.shadows[6] =
  "0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4)";

export default theme;
