import React, { useRef, useState } from "react";
import theme from "../../theme/theme";
import { Box, Button, ThemeProvider } from "@mui/material";
import Typography from "@mui/material/Typography";
import PopupDialog from "../../Components/PopupDialog";

const UIDev = () => {
  const [dialogOpen, setdialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setdialogOpen(!dialogOpen);
  };
  const fileInput = useRef();
  const dialogContent = (
    <>
      <Typography variant="InText">Content Here</Typography>
    </>
  );
  const dialogActions = (
    <>
      <Button variant="pill" color="primary" onClick={handleDialogOpen}>
        Confirm
      </Button>
      <Button variant="pill" color="secondary" onClick={handleDialogOpen}>
        Close
      </Button>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
          mt: 1,
          my: 1,
          overflowY: "auto",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{ display: "flex", gap: 2, overflowY: "auto", flexWrap: "wrap" }}
        >
          <Button variant="outlined" onClick={handleDialogOpen}>
            Outlined
          </Button>
          <Button variant="pill" color="red">
            <Typography variant="buttonText"> Red Pill</Typography>
          </Button>
          <Button variant="pill" color="red" className="active">
            <Typography variant="buttonText">Active Red Pill</Typography>
          </Button>
          <Button
            variant="pill"
            color="yellow"
            onClick={() => fileInput.current.click()}
          >
            Yellow Pill
            <input
              ref={fileInput}
              type="file"
              style={{ display: "none" }}
              onChange={console.log("Upload!")}
            />
          </Button>
          <Button variant="pill" color="yellow" className="active">
            Active Yellow Pill
          </Button>
          <Button variant="pill" color="primary">
            Primary Pill
          </Button>
          <Button variant="pill" color="primary" className="active">
            Active Primary Pill
          </Button>
          <Button variant="pill" className="disable">
            Disabled Pill
          </Button>
          <Button variant="pill" color="secondary">
            Secondary Pill
          </Button>
          <Button variant="pill" color="secondary" className="active">
            Active Secondary Pill
          </Button>
          <Button variant="contained" color="primary">
            Primary Contained
          </Button>
          <Button variant="contained" color="primary" className="active">
            Active Primary Contained
          </Button>
          <Button variant="contained" color="primary" className="disable">
            Disable Primary Contained
          </Button>
          <Button variant="contained" color="secondary">
            Secondary Contained
          </Button>
          <Button variant="contained" color="secondary" className="active">
            Active Secondary Contained
          </Button>
        </Box>
        <Box width={"50%"} display={"flex"} gap={2}>
          <Button variant="pill" color="primary" sx={{ width: "100%" }}>
            Log in
          </Button>
          <Button variant="pill" color="secondary" sx={{ width: "100%" }}>
            Create Account
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Typography variant="Title">Title</Typography>
          <Typography variant="SubTitle">SubTitle</Typography>
          <Typography variant="Input">Input</Typography>
          <Typography variant="InText">InText</Typography>
          <Typography variant="SuggestionCardBodyText">
            SuggestionCardBodyText
          </Typography>
          <Typography variant="EditorBodyText">EditorBodyText</Typography>
          <Typography variant="ButtonTextSmall">ButtonTextSmall</Typography>
          <Typography variant="ButtonText">ButtonText</Typography>

          {/* Use other variants as needed */}
        </Box>
        <PopupDialog
          open={dialogOpen}
          dialogTitle={"Do you want to sign out?"}
          dialogContent={dialogContent}
          dialogActions={dialogActions}
        />
      </Box>
    </ThemeProvider>
  );
};

export default UIDev;
