import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Checkbox,
  Container,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Typography, Dialog, Button } from "@mui/material/";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { db, storage } from "../../Firebase";
import { writeBatch } from "firebase/firestore";

import {
  deleteObject,
  ref,
  getStorage,
  getDownloadURL,
} from "firebase/storage";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import API from "../../Utils/API";
import FileSaver from "file-saver";
import theme from "../../theme/theme";
import TagButtons from "./TagButtons";
import {
  Add,
  BookmarkAdd,
  CheckBox,
  CheckBoxOutlineBlank,
  FilterList,
  Search,
  Sort,
  SortByAlpha,
} from "@mui/icons-material";
import PopupDialog from "../../Components/PopupDialog";
import CustomEllipsis from "../../Utils/CustomEllipsis";
import TagContext from "../../hooks/TagContext";
import ProjectContext from "../../hooks/ProjectContext";

const DocumentTable = (props) => {
  const navigate = useNavigate();
  const {
    documents,
    selectedTag,
    setSelectedTag,
    openAddTag,
    setOpenAddTag,
    handleCloseAddTag,
    handleAddTag,
    tagList,
    setTagList,
  } = props;
  const projectContext = useContext(ProjectContext);
  const { handleOpenTagSnackbar } = useContext(TagContext);
  const [selected, setSelected] = useState("");
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [docToDelete, setDocToDelete] = useState(null);
  const [params, setParams] = useState("");
  const [sortType, setSortType] = useState("A to Z");
  const [openActionDialog, setOpenActionDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedMenuTag, setSelectedMenuTag] = useState("Tag");
  const [action, setAction] = useState("");
  const open = Boolean(anchorEl);

  const searchAndSortData = useCallback(
    (data) => {
      let filteredData = data;
      // console.table({ sortType: sortType });
      if (params !== "") {
        // console.log(params);
        filteredData = data.filter((doc) => {
          return doc.title.toLowerCase().includes(params.toLowerCase());
        });
        // console.log(filteredData);
      }

      if (projectContext.selectedProject !== "") {
        filteredData = filteredData.filter((doc) => {
          return doc.projects?.includes(projectContext.selectedProject);
        });
      }

      // console.log(selectedTag);
      if (selectedTag !== "All document") {
        filteredData = filteredData.filter((doc) => {
          return doc.tags.includes(selectedTag);
        });
      }

      const _sortedData = [...filteredData];
      const sortData = (a, b) => {
        if (sortType === "Recent") {
          return b.lastUpdatedTimestamp - a.lastUpdatedTimestamp;
        }
        if (sortType === "A to Z") {
          // console.log(a.title);
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
          return 0;
        }
      };
      _sortedData.sort(sortData);
      setSortedData(_sortedData);
    },
    [params, projectContext.selectedProject, selectedTag, sortType]
  );
  useEffect(() => {
    if (documents) {
      searchAndSortData(documents);
    }
  }, [documents, sortType, params, selectedTag, searchAndSortData]);

  useEffect(() => {
    if (selectedTag === "All document") {
      setSelectedMenuTag("Tag");
    }
  }, [selectedTag]);
  const titleStyle = {
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    color: "#2C82C1",
  };

  const contentStyle = {
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#525252",
  };

  if (!documents) {
    return <div>Loading...</div>;
  }

  const handleMenuClick = async (e, buttonText) => {
    setAnchorEl(e.currentTarget);
    setMenu(buttonText.toLowerCase());
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenu("");
  };

  const handleNavigation = (id) => {
    const path = `/portal/draft?id=${id}`;
    navigate(path);
  };

  const handleOption = (id) => {
    setSelected(id);
  };

  const handleDelete = (doc) => {
    setDocToDelete(doc);
    setDeleteOpen(true);
  };
  const handleDeleteCancel = () => {
    setDeleteOpen(false);
  };
  const handleDeleteConfirm = (id) => {
    deleteFromFirebase(id);
    setDeleteOpen(false);
  };

  const handleCopy = (doc) => {
    createNewInstanceInFirebase(doc);
  };

  const handleTagChange = (tagName) => {
    setSelectedTag(tagName);
  };

  const handleSelectDocument = (docId) => {
    if (!docId || !selectedDocuments) {
      return;
    }

    if (selectedDocuments.includes(docId)) {
      setSelectedDocuments(selectedDocuments.filter((id) => id !== docId));
    } else {
      setSelectedDocuments([...selectedDocuments, docId]);
    }
  };

  const handleMenuTagChange = (e) => {
    setSelectedMenuTag(e);
  };

  const handleAddTagFromMenu = () => {
    var warningFlag = false;
    const batch = writeBatch(db);
    if (selectedMenuTag === "Tag") {
      handleOpenTagSnackbar("Select A Tag In The Menu First", "error");
      return;
    }
    documents.forEach((document) => {
      if (selectedDocuments.includes(document.id)) {
        const documentRef = doc(db, "files", document.id);
        if (document.tags.includes(selectedMenuTag)) {
          handleOpenTagSnackbar(
            `Document "${document.title}" Already Has the tag "${selectedMenuTag}"!`,
            "warning"
          );
          warningFlag = true;
          return;
        }
        document.tags = [...document.tags, selectedMenuTag];
        if (!tagList.includes(selectedMenuTag)) {
          setTagList((prev) => {
            return [...prev, selectedMenuTag];
          });
        }
        batch.update(documentRef, { tags: document.tags });
      }
    });
    if (!warningFlag) {
      batch
        .commit()
        .then(() => {
          handleOpenTagSnackbar(
            `Added the tag "${selectedMenuTag}" to the Documents!`,
            "success"
          );
        })
        .catch((error) => {
          console.error("Error updating documents:", error);
        });
    }
  };
  const createNewInstanceInFirebase = async (doc) => {
    await API.createNewInstanceInFirebase(doc);
  };
  const handleExport = async () => {
    selectedDocuments.forEach((docId) => {
      const selectedDocument = documents.find((doc) => doc.id === docId);
      if (selectedDocument) {
        console.info("doc: " + selectedDocument.url);
        handleDownload(selectedDocument);
      }
    });
  };
  const handleDownload = (doc) => {
    console.info("doc: " + doc.url);
    const storage = getStorage();
    getDownloadURL(ref(storage, `${doc.url}`))
      .then((url) => {
        // `url` is the download URL for 'doc'
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          // eslint-disable-next-line no-unused-vars
          const blob = xhr.response;
        };
        xhr.open("GET", url);
        xhr.send();
        FileSaver.saveAs(url, `${doc.title}.html`);
      })
      .catch((error) => {
        // Handle any errors
      });
  };

  /**
   * Takes in a String id, deletes a file in Firebase and a document from
   * Firestore
   * @param {*} id
   * @return {*}
   */
  const deleteFromFirebase = async (id) => {
    // //Get target document from Firebase, if not available then return
    const fileRef = doc(db, "files", id);
    const docSnap = await getDoc(fileRef);
    if (!docSnap.exists) {
      return;
    }
    // console.log("Doc Snap ", docSnap);

    // //Get File Link
    const fileLink = docSnap.data().fileLink;
    // //Delete Document from Firestore
    try {
      await deleteDoc(fileRef);
    } catch (error) {
      alert(`Error deleting file Firestore. Error: ${error}`);
    }

    //Delete File from Storage
    const httpsReference = ref(storage, fileLink);
    try {
      await deleteObject(httpsReference);

      searchAndSortData(documents.filter((item) => item.id !== id));
    } catch (error) {
      alert(`Error deleting file in Storage. Error: ${error}`);
    }
  };

  //Local Component
  const DocumentMenu = () => {
    let content = [];
    switch (menu) {
      case "tag":
        content = (
          <MenuList>
            {tagList &&
              tagList.map((tag, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleMenuTagChange(tag);
                      setAnchorEl(false);
                    }}
                  >
                    {tag}
                  </MenuItem>
                );
              })}
          </MenuList>
        );
        break;
      case "sort":
        const sortMenu = ["Recent", "A to Z"];
        content = (
          <MenuList>
            {sortMenu &&
              sortMenu.map((option, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setSortType(option);
                      setAnchorEl(false);
                    }}
                  >
                    {option}
                  </MenuItem>
                );
              })}
          </MenuList>
        );
        break;
      default:
        break;
    }
    return (
      <Menu
        id="document-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {content}
      </Menu>
    );
  };
  const AddTagDialog = () => {
    const [newTagName, setNewTagName] = useState("");
    const [selectedDoc, setSelectedDoc] = useState([]);
    const dialogTitle = "Insert a new tag";
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: "10vw",
        },
      },
    };
    const _documentList = documents;
    const DocumentSelect = () => {
      const handleChange = (event) => {
        setSelectedDoc(event.target.value);
      };
      return (
        <div>
          <InputLabel id="document-checkbox-label">Documents</InputLabel>
          <Select
            sx={{
              "& .MuiInputLabel-shrink": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              },
              width: "100%",
            }}
            labelId="document-multiple-checkbox-label"
            id="document-multiple-checkbox"
            multiple
            onChange={handleChange}
            value={selectedDoc}
            renderValue={(selected) =>
              selected.map((item) => item.title).join(", ")
            }
            MenuProps={MenuProps}
          >
            {_documentList.map((document) => (
              <MenuItem key={document.id} value={document}>
                <Checkbox
                  checked={
                    selectedDoc.findIndex((doc) => {
                      return doc.title === document.title;
                    }) > -1
                  }
                />
                <ListItemText
                  primary={document.title}
                  primaryTypographyProps={{
                    sx: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    };

    const dialogContent = (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <TextField
          variant="outlined"
          placeholder="New Tag Name"
          value={newTagName}
          onChange={(e) => {
            setNewTagName(e.target.value);
          }}
          size="small"
          sx={{ width: "100%" }}
        />
        {newTagName === "" && (
          <Typography variant="InText" color={theme.palette.status.error}>
            Tag Name Must not Be Empty
          </Typography>
        )}
        <DocumentSelect />
      </Box>
    );
    const dialogActions = (
      <>
        <Button variant="pill" color="secondary" onClick={handleCloseAddTag}>
          Cancel
        </Button>
        <Button
          variant="pill"
          color="primary"
          onClick={() => handleAddTag(newTagName, selectedDoc)}
          disabled={newTagName === "" || selectedDoc.length === 0}
        >
          Confirm
        </Button>
      </>
    );
    return (
      <PopupDialog
        open={openAddTag}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        dialogActions={dialogActions}
        dialogIcon={<BookmarkAdd />}
        close={handleCloseAddTag}
      />
    );
  };
  const DocumentActionDialog = () => {
    let dialogTitle = "";
    let dialogContent = <></>;
    let dialogActions = <></>;
    switch (action) {
      case "copy":
        dialogTitle = "Confirm Copy";
        break;
      case "delete":
        dialogTitle = "Confirm Delete";

        break;
      case "export":
        dialogTitle = "Confirm Export";

        break;
      default:
        return null;
    }
    return (
      <PopupDialog
        open={openActionDialog}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        dialogActions={dialogActions}
      />
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box className="table-title-wrapper" sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="tagButton"
          sx={{
            backgroundColor:
              selectedTag === "All document" ? "#737B82" : "#FFFFFF",
            color: selectedTag === "All document" ? "#FFFFFF" : "#737B82",
          }}
          onClick={() => handleTagChange("All document")}
        >
          All document
        </Button>
        <Button
          variant="tagButton"
          onClick={() => {
            setOpenAddTag(true);
          }}
        >
          <Add></Add>
        </Button>
        <TagButtons
          selectedTag={selectedTag}
          tagList={tagList}
          onSelect={handleTagChange}
        ></TagButtons>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItem: "center",
        }}
      >
        <Box id="menu-box" sx={{ display: "flex", alignItems: "center" }}>
          <Button onClick={(e) => handleMenuClick(e, "Filter")} variant="text">
            <FilterList />
            Filter
          </Button>
          <Button onClick={(e) => handleMenuClick(e, "Sort")} variant="text">
            {sortType === "Recent" && <Sort />}
            {sortType === "A to Z" && <SortByAlpha />}
            {sortType}
          </Button>
          <Button onClick={(e) => handleMenuClick(e, "Tag")} variant="text">
            <BookmarkAdd />
            {selectedMenuTag}
          </Button>
          <Button onClick={() => handleExport()} variant="text">
            <DownloadIcon />
            Export
          </Button>
          {selectedDocuments.length !== 0 && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddTagFromMenu}
            >
              Add To Tag
            </Button>
          )}
          <DocumentMenu></DocumentMenu>
        </Box>

        <Input
          id="search-box"
          placeholder="Search Document"
          value={params || ""}
          onChange={(e) => setParams(e.target.value)}
          sx={{ width: "20%" }}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </Box>
      <div className="tableContainer">
        {sortedData ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Typography className="tableCellText">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography className="tableCellText">
                    Last modified
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="tableCellText">
                    Document Type
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="tableCellText">Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((doc) => {
                return (
                  <TableRow
                    key={doc.title}
                    className="document"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f6f6f6",
                      },
                    }}
                    onMouseEnter={() => setSelected(doc.id)}
                    onMouseLeave={() => setSelected("")}
                  >
                    <TableCell
                      onClick={() => handleSelectDocument(doc.id)}
                      sx={{ width: "5%" }}
                    >
                      <IconButton>
                        {selectedDocuments.includes(doc.id) ? (
                          <CheckBox />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell onClick={() => handleNavigation(doc.id)}>
                      <Typography className="tableCellText">
                        {CustomEllipsis({ text: doc.title, maxLength: 70 })}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="tableCellText">
                        {doc.lastUpdated}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{ display: "flex", height: "100%" }}
                        id={`${doc.id}-tags`}
                      >
                        <TagButtons
                          tagList={doc.tags}
                          onSelect={handleTagChange}
                        />
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: "10%", padding: 0 }}>
                      <IconButton
                        id="moreButton"
                        color="primary"
                        aria-label="MoreVertIcon"
                        component="label"
                        sx={{
                          display:
                            selected === doc.id ? "none" : "inline-block",
                        }}
                        onClick={() => handleOption(doc.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <IconButton
                        className="optionButtons"
                        color="primary"
                        aria-label="DeleteIcon"
                        component="label"
                        onClick={() => handleDelete(doc)}
                        sx={{
                          display:
                            selected === doc.id ? "inline-block" : "none",
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Dialog
                        open={isDeleteOpen}
                        onClose={handleDeleteCancel}
                        BackdropProps={{
                          style: {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                          },
                          onClick: handleDeleteCancel,
                        }}
                        PaperProps={{
                          sx: {
                            backgroundColor: "white",
                            borderRadius: "10px",
                            boxShadow: 1,
                            p: 2,
                            width: "50%",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          },
                        }}
                      >
                        <DialogTitle
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={titleStyle}>
                            Confirm to Delete
                          </Typography>
                        </DialogTitle>
                        <DialogContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={contentStyle}>
                            {docToDelete ? docToDelete.title : ""}
                          </Typography>
                          <Container
                            sx={{
                              marginTop: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              variant="Contained"
                              onClick={() => {
                                handleDeleteConfirm(docToDelete.id);
                              }}
                              sx={{
                                background: "#2C82C1",
                                borderRadius: "20px",
                                color: "#FFF",
                                "&:hover": {
                                  background: "#FFF",
                                  color: "#2C82C1",
                                  border: "1px solid #D4D4D4",
                                },
                              }}
                            >
                              Confirm
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={handleDeleteCancel}
                              sx={{
                                borderRadius: "20px",
                                "&:hover": {
                                  background: "#2C82C1",
                                  color: "#FFF",
                                  border: "1px solid #D4D4D4",
                                },
                              }}
                            >
                              Cancel
                            </Button>
                          </Container>
                        </DialogContent>
                      </Dialog>
                      <IconButton
                        className="optionButtons"
                        color="primary"
                        aria-label="ContentCopyIcon"
                        component="label"
                        onClick={() => handleCopy(doc)}
                        sx={{
                          display:
                            selected === doc.id ? "inline-block" : "none",
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                      <IconButton
                        className="optionButtons"
                        color="primary"
                        aria-label="DownloadIcon"
                        component="label"
                        onClick={() => handleDownload(doc)}
                        sx={{
                          display:
                            selected === doc.id ? "inline-block" : "none",
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <p>No Documents</p>
        )}
      </div>
      <AddTagDialog />
      <DocumentActionDialog />
    </Box>
  );
};

export default DocumentTable;
