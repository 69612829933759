import { useState } from "react";
import {
  Box,
  rgbToHex,
  styled,
  Typography,
  Tab,
  Tabs,
  ThemeProvider,
} from "@mui/material";
import PropTypes from "prop-types";

import LoginComponent from "./LoginComponent";
import SignupComponent from "./SignupComponent";

import backgroundImage from "../../Image/login_background_.png";
import Logo from "../../Image/logo_full.png";

import theme from "../../theme/theme";

import "./login.css";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "100%",
  position: "fixed",
  top: "0",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  textAlign: "left",
}));

const StyledText = styled(Box)(({ theme }) => ({
  fontFamily: "Raleway",
  fontSize: "2em",
  fontWeight: "800",
  lineHeight: "1.15em",
  textAlign: "left",
  color: "#0c5e9b",
}));

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  fontFamily: "Raleway",
  fontSize: "1.75em",
  fontWeight: "700",
  lineHeight: "1.15em",
  textTransform: "none",
  "&.MuiTab-root": {
    color: theme.palette.typography.remark,
  },
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "no-wrap",
            overflow: "auto",
            // justifyContent:'center',
            alignItems: "center",
          }}
        >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Login = () => {
  const [Islogin, setIslogin] = useState(true);
  const [value, setValue] = useState(0);

  const handleSwitchToSignUp = () => {
    setValue(1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <StyledBox
          className="login-background"
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            //   backgroundColor: "#0c5e9b",
            left: "0",
          }}
        >
          <Box
            sx={{
              background: "rgba(255,255,255,0.5)",
              position: "absolute",
              top: "50%",
              left: "50%",
              backdropFilter: "blur(25px)",
              width: "70%",
              height: "69.3vh",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translate(12%, -50%)",
              }}
            >
              <LogoContainer sx={{ mb: 2 }}>
                <img className="logo" src={Logo} alt="logo" />
              </LogoContainer>
              <StyledText>In charge of your</StyledText>
              <StyledText
                sx={{
                  color: "white",
                }}
              >
                contracts
              </StyledText>
            </Box>
          </Box>
        </StyledBox>
        <form>
          <Box
            className="login-form"
            sx={{
              position: "absolute",
              top: "20.32vh",
              left: "calc(75% - 47.44vh / 2)",
              py: 1,
              mb: 1,
              mx: 1,
              // bgcolor: 'background.paper',
              width: "47.44vh",
              minHeight: "294.13px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                mb: 3,
              }}
              variant="fullWidth"
            >
              <StyledTab label="Log In" {...a11yProps(0)} />
              <StyledTab label="Sign Up" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <LoginComponent handleSwitchToSignUp={handleSwitchToSignUp} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SignupComponent />
            </TabPanel>
            {/* {Islogin ? (
            <LoginComponent handleSwitchToSignUp={handleSwitchToSignUp} />
          ) : (
            <SignupComponent />
          )} */}
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
