import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "../theme/theme";

const PopupDialog = ({
  dialogTitle,
  dialogContent,
  dialogActions,
  dialogIcon,
  close,
  closeButton,
  ...props
}) => {
  if (closeButton === undefined) {
    closeButton = true;
  }
  return (
    <Dialog
      {...props}
      PaperProps={{
        sx: {
          boxSizing: "border-box",
          height: "fit-content",
          width: "fit-content",
          minwidth: "25vw",
          borderRadius: "20px",
          padding: "2rem",
          boxShadow: 6,
          display: "flex",
        },
      }}
    >
      {dialogTitle && dialogIcon && (
        <DialogTitle
          sx={{
            padding: 0,
            display: "flex",
            justifyContent: "space-between",
            minWidth: "100%",
          }}
        >
          <Box
            className="titleLeft"
            sx={{ display: "flex", flexDirection: "column", minWidth: "100%" }}
          >
            <Box
              className="upper-title"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "100%",
              }}
            >
              <Icon sx={{ color: theme.palette.primary.main }}>
                {dialogIcon}
              </Icon>

              {closeButton && (
                <IconButton onClick={close}>
                  <Close />
                </IconButton>
              )}
            </Box>
            <Typography variant="Title" color="primary.main">
              {dialogTitle}
            </Typography>
          </Box>
        </DialogTitle>
      )}
      {dialogTitle && !dialogIcon && (
        <DialogTitle
          sx={{
            padding: 0,
            display: "flex",
            justifyContent: "space-between",
            minWidth: "100%",
          }}
        >
          <Box className="titleLeft">
            <Typography variant="Title" color="typography.2">
              {dialogTitle}
            </Typography>
          </Box>
          {closeButton && (
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {dialogContent && (
        <DialogContent
          sx={{
            boxSizing: "border-box",
            padding: 0,
            display: "flex",
            alignItems: "center",
            minHeight: "15vh",
            width: "100%",
          }}
        >
          {dialogContent}
        </DialogContent>
      )}
      {dialogActions && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {dialogActions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PopupDialog;
