import React, { useState, useEffect, useContext, useCallback } from "react";
import Typography from "@mui/material/Typography";
import SimpleAccordion from "../../Components/SimpleAccordion";
import DataAPI from "../../Utils/DataAPI";
import TrafficLight from "../../Components/TrafficLight.js";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import HTMLReactParser from "html-react-parser";
import DocumentContext from "../../hooks/DocumentContext";
import API from "../../Utils/API";
function Draft() {
  const { documentData, setDocumentData, loadDocument, titleWithId } =
    useContext(DocumentContext);
  const [recommendationArray, setRecommendationArray] = useState({
    value: null,
    loading: true,
    error: false,
  });

  //function is deprecated because TinyMCE is not included in this design
  // const handleContentChange = (newContent) => {
  //   setContent(newContent);
  // };

  //Handles Content Change By Accordions
  const handleModification = (recommendation) => {
    // console.log(recommendation);
    // const _tempContent = window.tinymce.activeEditor.getContent();
    const _tempContent = documentData.value;
    const tempDiv = document.createElement("div");
    let editSuccess = false;
    tempDiv.innerHTML = _tempContent;
    // console.log(tempDiv.innerHTML);
    const _tempRecommendationDiv = document.createElement("div");
    _tempRecommendationDiv.innerHTML = recommendation.modified_text;
    recommendation.sentenceReference?.forEach((sentenceRef) => {
      var modification = _tempRecommendationDiv.querySelector(
        `#${sentenceRef}`
      );
      var sentence = tempDiv.querySelector(`#${sentenceRef}`);
      if (modification && sentence) {
        sentence.innerHTML = modification.innerHTML;
        sentence.classList.add("highlight");
        editSuccess = true;
      }
      if (editSuccess) {
        setDocumentData({
          value: tempDiv.innerHTML,
          loading: true,
          error: false,
        });
        // recommendation.active = false;
        editSuccess = false;
      }
    });
  };

  const handleConfirm = (recommendation) => {
    //Create Temporary HTML Elements for filtering out the terms and sentences
    const _content = document.getElementById("document-content");
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = _content.innerHTML;

    //Handles Highlight of the Change
    const _hightlightedSpan = tempDiv.getElementsByClassName("highlight");
    for (const element of _hightlightedSpan) {
      element.classList.remove("highlight");
    }
    const documentContent = tempDiv.innerHTML;

    //After the Change Upload the Modified Document the Firebase
    API.saveContentToFirebase(documentContent, titleWithId);

    //Remove the Recommendation from the state
    const _modifiedRecommendationArray = recommendationArray.value.filter(
      (rec) => rec !== recommendation
    );
    setRecommendationArray({
      value: _modifiedRecommendationArray,
      loading: true,
      error: false,
    });
    getRecommendationData(documentContent);
  };
  const getRecommendationData = useCallback(
    async (_content) => {
      try {
        let data = await DataAPI.getRecommendation(_content);
        setRecommendationArray({ value: data, error: false, loading: false });
        const _newContent = data[0].original_text;
        setDocumentData({ loading: false, error: true, value: _newContent });
      } catch (error) {
        setRecommendationArray({ value: error, error: true, loading: false });
      }
    },
    [setDocumentData]
  );
  useEffect(() => {
    if (!documentData.value && !documentData.error) {
      loadDocument();
    } else if (!recommendationArray.value) {
      const fetchData = async () => {
        await getRecommendationData(documentData.value);
      };
      fetchData();
    }
    //Debug Use, Check DocumentData
    console.log(documentData);
  }, [
    documentData,
    getRecommendationData,
    loadDocument,
    recommendationArray.value,
    setDocumentData,
  ]);
  return (
    <>
      <div style={{ display: "flex", marginTop: "6vh" }}>
        {/* Left Box */}
        <div
          style={{
            width: "50%",
            borderRight: "1px solid lightgrey",
            height: "94vh",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
          id="document-content"
        >
          {/* <TinyEditorComponentClassic
            content={content}
            updateContent={handleContentChange}
          /> */}

          {documentData.value && HTMLReactParser(documentData.value)}
        </div>
        {/* Right Box */}
        <div
          style={{
            height: "94vh",
            width: "50%",
            maxWidth: "50%",
            borderLeft: "1px solid lightgrey",
            backgroundColor: "#FAFAFA",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              height: "85px",
              backgroundColor: "#FFF",
              borderBottom: "1px solid lightgrey",
              flexDirection: "column",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <Typography
              variant="h5"
              textAlign="center"
              fontSize="20px"
              style={{
                olor: "#666666",
                fontFamily: "Raleway",
              }}
            >
              Terms Suggestions
            </Typography>
            <div style={{ borderBottom: "1px solid lightgrey" }} />
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <TextField
                  placeholder="Want to add a term but do not know how to write it? You search it, we find it."
                  // value={searchTerm}
                  variant="standard"
                  // onChange={handleSearchChange}
                  sx={{ width: "90%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      textAlign: "center",
                      justifyItems: "center",
                      color: "#959595 ",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ ml: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "30px",
                  color: "#525252",
                  borderColor: "#F8C218",
                  backgroundColor: "#FFF8E2",
                }}
                // onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
          {/* Recommendation Buttons */}
          <Box
            sx={{
              maxWidth: "100%",
              display: "flex",
              p: 1,
              height: "80%",
              justifyContent: "center",
            }}
            id="recommendation-panel-container"
          >
            {recommendationArray.error && (
              <span>{recommendationArray.error}</span>
            )}
            {!recommendationArray.loading && !recommendationArray.error && (
              <SimpleAccordion
                accordions={
                  recommendationArray.value ? recommendationArray.value : []
                }
                addFunction={handleModification}
                confirmFunction={handleConfirm}
              ></SimpleAccordion>
            )}
            {recommendationArray.loading && !recommendationArray.error && (
              <CircularProgress sx={{ alignSelf: "center" }} />
            )}
          </Box>
        </div>
        <TrafficLight />
      </div>
    </>
  );
}

export default Draft;
