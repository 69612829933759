import { Helmet } from 'react-helmet';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import './userpage.css'
import pen_full from "../../Image/pen_full.png"
import pen_mid from "../../Image/pen_mid.png"
import pen_low from "../../Image/pen_low.png"
import password_pen from "../../Image/Icons/pen.png"
import tick from "../../Image/Icons/tick.png"
// @mui
import {
  Paper,
  Container,
} from '@mui/material';
import { margin } from '@mui/system';
// ----------------------------------------------------------------------
import { faker } from '@faker-js/faker';


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));


export default function UserPage() {
  const [subMonth, setSubMonth] = React.useState(0);
  const [subDate, setSubDate] = React.useState("0/0/0000");

  // const [values, setValues] = React.useState({
  //   amount: '',
  //   password: '',
  //   weight: '',
  //   weightRange: '',
  //   showPassword: false,
  // });

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  // const handleClickShowPassword = () => {
  //   setValues({
  //     ...values,
  //     showPassword: !values.showPassword,
  //   });
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };


  return (
    <>
      <Helmet>
        <title>User</title>
      </Helmet>

      <Container sx={{ gap: '2vw', display: 'flex', flexDirection: 'column', marginTop:'5rem'}}>
          <div className='textField'>
            <div className='subTextField'>First Name<div className='requied'>*</div></div>
          <div className='accountTextField'>
            {faker.name.firstName()}
          </div>
          </div>
          <div className='textField'>
          <div className='subTextField'>Last Name<div className='requied'>*</div></div>
          <div className='accountTextField'>
            {faker.name.lastName()}
          </div>
          </div>
          <div className='textField'>
          <div className='subTextField'>Email<div className='requied'>*</div></div>
          <div className='accountTextField'>
            {faker.internet.email()}
            <img src={tick} className="icon"/>
          </div>
          </div>
          <div className='textField'>
          <div className='subTextField'>Password<div className='requied'>*</div></div>
          <div className='accountTextField'>
            *******
            <img src={password_pen} className="icon"/>
          </div>
          </div>
          <div className='textField'>
            Company name
          <div className='accountTextField'>
            Contracfy
          </div>
          </div>
          <div className='textField'>
            HKID
          <div className='accountTextField'>
            {faker.vehicle.vin()}
          </div>
          </div>
          <div className='textField'>
            Subscription plan
            <div className='subscription'>
              {
              subMonth > 12 ?
              <img src={pen_full} />
              :
              subMonth > 6 ?
              <img src={pen_mid} />
              :
              <img src={pen_low} />
              }
              <div className='flex-col'>
                <div className='subscription_header'>
                Your subscription has {subMonth} month left
                </div>
                <div className='subscription_text'>
                till {subDate}
                </div>
              </div>
              <button className='subscription_button'>Renew</button>
            </div>
          </div>
      </Container>


    </>
  );
}
