// @mui
import { Box, Button, Container, Stack, Typography } from "@mui/material";
// import draftingfromnew from '../Dashboard/pages/draftingFromNew.jpg'
import { ThemeProvider, styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
// import mammoth from "mammoth";
// import docxToHtml from "docx-to-html";
import * as React from "react";
import { useNavigate } from "react-router";
import { storage, db } from "../../Firebase";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { getAuth } from "firebase/auth";
import API from "../../Utils/API";

import "./blogpage.css";
import PopupDialog from "../../Components/PopupDialog";
import theme from "../../theme/theme";

const ContractButton = styled(Button)(({ theme }) => ({
  boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
  borderRadius: "20px",
  border: "none",
  height: "41px",
  width: "168px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "47px",
  color: "white",
  textTransform: "none",
}));

export default function UploadPage(props) {
  const [open, setOpen] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState({
    upload: false,
    receive: false,
  });
  const [file, setFile] = React.useState(null);
  // const upload = React.useRef(null);
  const navigate = useNavigate();
  const fileInput = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    fileInput.current.value = null;
    fileInput.current.files = null;
    setFile(null);
    setOpen(false);
  };

  function handleNavigation(id) {
    const path = `/portal/draft?id=${id}`;
    navigate(path);
  }

  const handleUpload = async (e) => {
    setUploadFile({ upload: true, receive: false });

    const auth = getAuth();
    const currentUser = auth.currentUser;
    let htmlString = "";

    //User is not authorized, Early return
    if (!currentUser) {
      return;
    }

    if (file != null) {
      const _filename = `${currentUser.uid}_${file.name
        .split(".")
        .slice(0, -1)
        .join(".")}`;
      const storageRef = ref(storage, `uploadedDocs/${_filename}`);

      let type = file.name.split(".").pop();
      console.log("The Types ", type);

      //../Dashboard/Utils/API
      await API.getDocument(file, type).then((result) => {
        if (result) {
          // setUploadFile({ upload: false, receive: true });
          htmlString = result;
          // console.log(htmlString)
        }
      });

      //No result from API, early return
      if (!uploadFile.receive) {
        console.log("No result from API, early return");
        alert("File Upload Failed");
        return;
      }

      //upload the file read
      await uploadBytes(
        storageRef,
        new Blob([htmlString], { contentType: "text/html" })
      ).then(async () => {
        const downloadURL = await getDownloadURL(storageRef);
        const fileData = {
          fileName: _filename,
          fileLink: downloadURL,
          lastOpenTime: new Date(),
          lastUpdateTime: new Date(),
          userId: currentUser.uid,
          tags: [],
        };

        //If file already exists, modify document accordingly
        //Else create a new document for the file
        if (await checkFilesExist(fileData.fileName, currentUser.uid)) {
          console.log("File Already Exist, modify document accordingly");
          const filesCollection = collection(db, "files");
          const querySnapshot = await getDocs(
            query(
              filesCollection,
              where("fileName", "==", fileData.fileName),
              where("userId", "==", currentUser.uid)
            )
          );
          const docRef = querySnapshot.docs[0].ref;
          await updateDoc(docRef, {
            fileLink: fileData.fileLink,
            lastUpdateTime: new Date(),
          }).then(() => {
            // setUploadFile({ upload: false, receive: true });
            handleNavigation(docRef.id);
          });
        } else {
          const filesCollection = collection(db, "files");
          await addDoc(filesCollection, fileData)
            .then((docRef) => {
              console.log(`File upload is Successful`);
              console.table(fileData);
              // setUploadFile({ upload: false, receive: true });
              handleNavigation(docRef.id);
            })
            .catch((error) => {
              console.error(`Error when Uploading file, ${error}`);
            });
        }
      });
    }
  };

  /**
   * @export
   * @param {file} file
   * @return {*} HTML string
   */
  const handleInput = (e) => {
    setUploadFile({ upload: false, receive: true });
    setFile(e.target.files[0]);
    console.table(e.target.files[0]);
    handleClickOpen();
    return;
  };

  /**
   * Takes file name and user id and checks if the file already exists in the database.
   *
   * @param {String} fileName
   * @param {String} userId
   * @return {Boolean} querySnapshot.empty
   */
  const checkFilesExist = async (fileName, userId) => {
    const filesCollection = collection(db, "files");
    const querySnapshot = await getDocs(
      query(
        filesCollection,
        where("fileName", "==", fileName),
        where("userId", "==", userId)
      )
    );
    return !querySnapshot.empty;
  };

  const dialogActions = (
    <>
      <LoadingButton
        variant="pill"
        color="primary"
        onClick={handleUpload}
        disabled={!uploadFile.receive}
        loading={uploadFile.upload}
      >
        Confirm
      </LoadingButton>
      <Button variant="pill" color="secondary" onClick={handleClose}>
        Cancel
      </Button>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box className="uploadContainer">
        <ContractButton
          className="mainButton contractButton"
          sx={{ boxShadow: "none", borderRadius: "10px" }}
          onClick={() => {
            if (file) {
              //Clear existing file record
              setFile(null);
            }
            fileInput.current.click();
          }}
        >
          Upload
          <input
            accept=".docx, .pdf"
            ref={fileInput}
            type="file"
            style={{ display: "none" }}
            onChange={handleInput}
          />
        </ContractButton>
        <Typography color={theme.palette.secondary.main} fontWeight="500">
          pdf.,docx.,txt.
        </Typography>
      </Box>

      {file && (
        <PopupDialog
          open={open}
          dialogTitle="Confirm Upload?"
          dialogContent={
            <Typography variant="InText" color={theme.palette.primary.main}>
              {file.name}
            </Typography>
          }
          dialogActions={dialogActions}
        />
      )}
    </ThemeProvider>
  );
}
