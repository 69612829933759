
import Typography from '@mui/material/Typography';
// import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React, {useRef, useEffect} from 'react';
// import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

function ChatBox() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [messageQueue, setQueue] = React.useState([]);
  const [key, setKey] = React.useState(0);
  const [hasGreating, setGreating] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const messageEnd = useRef(null)


  const scrollToBottom = () => {
    messageEnd.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [update]);

  const handleClick = (event) => {
    
    if(!hasGreating){
      messageQueue.push({text:'Hello, how can I help you', character:'AI', id:'ai'+key});
      setKey(key+1)
      setGreating(true)
    }
  
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen(true)
    // 
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  // const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const ChatArea = useRef(null)
  const handleMessage = () =>{
    
    messageQueue.push({text:message, character:"human", id:"human"+key})
    setMessage("")
    setKey(key+1);
    askForAnswer(message);
  }

  const handleMessageKey = (e) =>{
    
    if(e.charCode === 13){
      e.preventDefault()
      messageQueue.push({text:message, character:"human", id:"human"+key})
      setMessage("")
      setKey(key+1);
      askForAnswer(message);
      
   }
    

  }

  const askForAnswer=(message)=>{
    fetch('https://songcpu1.cse.ust.hk/contracfy/api/getChatbotResponse', {
      method: 'POST',
      body: JSON.stringify({'question': message}),
      headers: {'Content-Type': 'application/json; charset=UTF-8','x-access-token':  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImN5bndlbGwiLCJpYXQiOjE2NzY2MzcxNjMsImV4cCI6MTcwODE5NDc2M30.Wfc8Akj3Lo8AVqiin4g7zbrAwy63_F3tkiXA-3Kc7Mw'}
    }).then(res => {
      console.log(res.status);
      return res.json()
    }).then(data => {
      console.log('Answer =', data['answer']['answer']);
      console.dir(data['answer']);
      // messageQueue.push({text:data['answer']['encoded'], character:"encode", id:"encode"+key});
      // setKey(key+1);
      messageQueue.push({text:data['answer']['answer'], character:"AI", id:"ai"+key});
      setKey(key+1);
      setUpdate(!update);
      
    }).catch((err)=> {
      console.log(err.message);
    })
  }


  return (
    <Box className="ChatBox">
      <IconButton size="large" sx={{color:"#96BBD7"}} onMouseDown={handleClick}>
         <QuestionAnswerIcon fontSize="large"></QuestionAnswerIcon>
      </IconButton>
      <Popper
      open ={open}
      anchorEl={anchorEl}
      placement={"top-end"} 
      transition
    >
      {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
      <Box sx={{borderRadius: "3vh", height: '60vh',width:'55vh',bgcolor:'background.paper',boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',}}>
      <Grid 
      container 
      spacing={0}
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      sx={{height: '100%'}}
      >
        <Grid container sx={{height:"10%", width:"100%"}}> 
          <Grid item xs={10}><Typography sx={{mt:1, fontSize:20, color:'#959595', ml:6 }}align="center">Chatbot</Typography></Grid>         
          <Grid item xs={2}>
            <IconButton onClick={()=>{handleClose()}} sx={{color:'#B6DAF3'}}>
              <CloseIcon ></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>
        <Box
        ref={ChatArea}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'no-wrap',
          overflow: 'auto',
          py: 1,
          mb: 1,
          mx: 1,
          // bgcolor: 'background.paper',
          borderRadius: 1,
          bgcolor:"",height:"68%", width:"100%"
        }}
        >
          {messageQueue.map((chat)=>{
            if(chat.character === "AI"){
              return(
                  <Box key={chat.id} sx={{m:1, maxWidth:'60%', bgcolor:'background.paper',boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)', alignSelf:'flex-start', borderRadius: '0 2vh 2vh 2vh'}}>
                    <Typography sx={{p:1, wordWrap: 'break-word'}}>{chat.text}</Typography>
                  </Box>
              )
            }
            // else if(chat.character === "encode"){
            //     return(
            //       <Box key={chat.id} sx={{m:1, maxWidth:'60%', bgcolor:'white', alignSelf:'flex-end'}}>
            //         <Typography sx={{p:1, color:'black', wordWrap: 'break-word'}}>{"encode: " + chat.text}</Typography>  
            //       </Box>     
                             
            //     )
            //   }
            else{
              return (
                <Box key={chat.id} sx={{m:1, maxWidth:'60%', bgcolor:'#96BBD7', boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)', alignSelf:'flex-end', borderRadius: '2vh 0 2vh 2vh'}}>
                  <Typography sx={{p:1, color:'white', wordWrap: 'break-word'}}>{chat.text}</Typography>
                </Box>
              )                
            }

            
          })}
          <div style={{ float:"left", clear: "both" }}
             ref={messageEnd}>
        </div>
        </Box>

        <Grid item xs={12} sx={{ height:"14%"}}>
          
          <TextField onChange={handleChange}  onKeyPress={handleMessageKey} value={message} label="type something" multiline maxRows={2} size="small" sx={{ ml:2, width: "80%"}} variant="outlined" />

          <IconButton onClick={handleMessage} sx={{color:'#B6DAF3',ml: 2}}>
            <SendIcon></SendIcon>
          </IconButton>

        </Grid>
      </Grid>
      
      </Box>
      </Fade>
      )}
    </Popper>
  </Box>
  )
}

export default ChatBox