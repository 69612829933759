/*
A Context Hook for fetching document list from Firestore
*/

import React from "react";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { db } from "../Firebase";
import { getAuth } from "firebase/auth";
const FirestoreContext = React.createContext();

export const FirestoreProvider = ({ children }) => {
  const [documentList, setDocumentList] = React.useState([]);

  React.useEffect(() => {
    const auth = getAuth();
    const uid = auth.currentUser.uid;
    const q = query(collection(db, "files"), where("userId", "==", uid));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedDocuments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setDocumentList(updatedDocuments);
    });

    return () => unsubscribe();
  }, []);

  const contextValue = {
    documentList,
    setDocumentList,
  };
  return (
    <FirestoreContext.Provider value={contextValue}>
      {children}
    </FirestoreContext.Provider>
  );
};

export default FirestoreContext;
