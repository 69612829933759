import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import theme from "../../theme/theme";
import { Box, Typography, styled, Button, Divider, ThemeProvider } from "@mui/material";
import { StyledInputBox, StyledButton } from "./styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import logo from "../../Image/logo_full.png";
import "./login.css";

import Logger from "../../Components/Logger";
import API from "../../Utils/API";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { set } from "date-fns";

const logger = new Logger(5);
const provider = new GoogleAuthProvider();

const SignupComponent = () => {
  const navigate = useNavigate();
  const [signupFail, setSignupFail] = useState(false);
  const [failmsg, setFailmsg] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [validLength, setValidLength] = useState(false);
  const [validLetter, setValidLetter] = useState(false);
  const [validNumber, setValidNumber] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);

  const handleInputChange = (e) => {
    setEmailError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);

    const tempData = { ...formData, [e.target.name]: e.target.value };
    const password = tempData.password;

    setIsPasswordEmpty((prev) => password === "");

    setValidLength((prev) => password.length >= 8);
    setValidLetter((prev) => /[a-zA-Z]/.test(password));
    setValidNumber((prev) => /[0-9]/.test(password));

    setFormData((prev) => tempData);

    logger.logInfo(5, "FormData: ", tempData);
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const handleSignInWithGoogle = async () => {
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // const user = result.user;
      // console.log(user);

      API.setCookie("JWTtoken", token, "/", "/", 180000);

      navigate("/portal/home");
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      logger.logInfo(2, errorCode, errorMessage, email, credential);
      throw error;
    }
  };

  const handleSignUp = async (data) => {
    const { email, password, confirmPassword } = formData;

    try {
      if (email === "") {
        setSignupFail(true);
        setEmailError(true);
        setFailmsg("Email cannot be empty");
        throw new Error("Email cannot be empty");
      }
      const isEmail = /\S+@\S+\.\S+/.test(email);
      if (!isEmail) {
        setSignupFail(true);
        setEmailError(true);
        setFailmsg("Email is not valid");
        throw new Error("Email is not valid");
      }

      if (password === "") {
        setSignupFail(true);
        setPasswordError(true);
        setFailmsg("Password cannot be empty");
        throw new Error("Password cannot be empty");
      }
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
      // const validPassword =
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(password);
      // Minimum eight characters, at least one letter and one number:
      const validPassword = /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(
        password
      );
      if (!validPassword) {
        setSignupFail(true);
        setPasswordError(true);
        setFailmsg("Password is not valid");
        throw new Error("Password is not valid");
      }

      if (confirmPassword === "") {
        setSignupFail(true);
        setConfirmPasswordError(true);
        setFailmsg("Confirm Password cannot be empty");
        throw new Error("Confirm Password cannot be empty");
      }
      if (password !== confirmPassword) {
        setSignupFail(true);
        setConfirmPasswordError(true);
        setFailmsg("Passwords do not match");
        throw new Error("Passwords do not match");
      }

      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const idToken = userCredential.user.getIdToken();
          API.setCookie("JWTtoken", idToken, "/", "/", 180000);

          navigate("/portal/home");
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            setSignupFail(true);
            setFailmsg("Email already exists");
          }
          const errorCode = error.code;
          const errorMessage = error.message;

          logger.logInfo(2, errorCode, errorMessage);
        });
    } catch (error) {
      logger.logInfo(2, error);
      throw error;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <Box sx={{position: "absolute", top: "4.47vh", left: "7%", width: "84%", height:"9.7%"}}> */}
      <StyledInputBox error={emailError}>
        {/* <TextField {...register("username", { required: true })} varient = "Outlined" sx={{width: "100%", bgcolor: "white", borderRadius: "1.79vh",border:0,height:"5vh"}} label="username"  size="small"></TextField> */}
        <input
          // {...register("username", { required: true })}
          type="text"
          name="email"
          placeholder="Email"
          className="loginInput"
          onChange={handleInputChange}
        ></input>
      </StyledInputBox>
      {/* <Box sx={{position: "absolute", top: "13vh", left: "7%", width: "84%", height:"9.7%"}}> */}
      <StyledInputBox sx={{ mt: 1.5 }} error={passwordError}>
        <input
          // {...register("password", { required: true })}
          type="password"
          name="password"
          placeholder="Password"
          className="loginInput"
          onChange={(e) => handleInputChange(e)}
        ></input>
      </StyledInputBox>
      <Box sx={{ width: "74%", mt: "1vh" }}>
        <PasswordHint
          text={"8 characters minimum"}
          valid={validLength}
          isPasswordEmpty={isPasswordEmpty}
        ></PasswordHint>
        <PasswordHint
          text={"Must contain one letter"}
          valid={validLetter}
          isPasswordEmpty={isPasswordEmpty}
        ></PasswordHint>
        <PasswordHint
          text={"Must contain one number"}
          valid={validNumber}
          isPasswordEmpty={isPasswordEmpty}
        ></PasswordHint>
      </Box>
      <StyledInputBox sx={{ mt: 1.5 }} error={confirmPasswordError}>
        <input
          // {...register("password", { required: true })}
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          className="loginInput"
          onChange={handleInputChange}
        ></input>
      </StyledInputBox>
      {signupFail && (
        <Box
          sx={{
            mb: "0.5vh",
            width: "74%",
            mt: "1vh",
            display: "flex",
            alignContent: "center",
            color: "#EB5757",
          }}
        >
          <ClearIcon sx={{ fontSize: "1.1em", margin: "auto 0" }} />
          <Typography
            sx={{
              fontSize: "1.1em",
              fontFamily: "Raleway",
              fontWeight: "500",
              lineHeight: "1.1em",
            }}
          >
            {failmsg}
          </Typography>
        </Box>
      )}
      <Box sx={{ width: "84%", m: 1, p: 1 }}>
        <Button 
          sx={{ width: "100%", height: "5.36vh"}}
          variant="pill" 
          color="primary" 
          onClick={handleSignUp}
          >
          Sign up
        </Button>
        <Divider sx={{ mt: 1.5, mb: 1.5 }} variant="middle" />
        <Button 
          sx={{ width: "100%", height: "5.36vh"}}
          variant="pill" 
          color="secondary"
          onClick={handleSignInWithGoogle}
          >
            <Typography
            style={{
              marginLeft: '1em',
              fontSize: '1.1em',
              fontFamily: 'Raleway',
              fontWeight: 500,
              color: '#0C5E9B',
            }}
          >Sign in with Google</Typography>
          
        </Button>
      </Box>
    </ThemeProvider>
  );
};

const PasswordHint = ({ text, valid, isPasswordEmpty }) => {
  return (
    <Box
      sx={{
        mb: "0.5vh",
        display: "flex",
        alignContent: "center",
        color: `${isPasswordEmpty ? "#959595" : valid ? "#56C568" : "#EB5757"}`,
      }}
    >
      {isPasswordEmpty ? null : valid ? (
        <CheckIcon sx={{ fontSize: "1em", margin: "auto 0" }} />
      ) : (
        <ClearIcon sx={{ fontSize: "1em", margin: "auto 0" }} />
      )}
      <Typography
        sx={{
          fontSize: "1em",
          fontFamily: "Raleway",
          fontWeight: "500",
          lineHeight: "1em",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default SignupComponent;
