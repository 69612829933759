import { Box, Button, styled } from "@mui/material";

export const StyledInputBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ theme, error }) => ({
  width: "84%",
  height: "5.36vh",
  "& ::placeholder": {
    color: theme.palette.typography.remark,
    fontFamily: "Raleway",
  },

  "& .loginInput": {
    borderRadius: "20px",
    border: "1px solid",
    borderColor: `${error ? "#EB5757" : theme.palette.lightBlue}`,
    fontSize: "1.1em",
    fontFamily: "Raleway",
    color: theme.palette.typography[2],
    width: "95%",
    height: "100%",
    padding: "0 0 0 5%",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "5.36vh",
  fontSize: "1.1em",
  fontFamily: "Raleway",
  alignItems: "center",
}));
