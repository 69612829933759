import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import Logger from "../Components/Logger";

import { db, storage } from "../Firebase";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const logger = new Logger(5);

const API = {
  authUser: async (username, password) => {
    return new Promise((resolve) => {
      try {
        fetch("https://songcpu1.cse.ust.hk/contracfy/api/login", {
          method: "POST",
          body: JSON.stringify({ username: username, password: password }),
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setWebCookie("JWTtoken", data.token);
            setWebCookie("User", username);
            // console.log(data);
            resolve(true);
          });
      } catch (err) {
        console.log(err);
        resolve(false);
      }
    });
  },
  signout: async () => {
    return new Promise((resolve) => {
      try {
        deleteWebCookie("JWTtoken");
        deleteWebCookie("User");
        logger.logInfo(4, "Delete cookies");
        resolve(true);
      } catch (err) {
        logger.logInfo(1, "Error in Log out", err);
        resolve(false);
      }
    });
  },
  getDocument: async (file, type) => {
    //Todo: add PDF support
    return new Promise((resolve) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        console.log(type);
        if (type === "pdf") {
          fetch(
            "https://songcpu1.cse.ust.hk/contracfy-dev/api/utilities/pdf-to-html",
            {
              method: "POST",
              body: formData,
              headers: { "x-access-token": getWebCookie("JWTtoken") },
            }
          )
            .then((res) => {
              console.log(res.status);
              return res.json();
            })
            .then((result) => {
              let htmlString = result.converted_file;
              // console.log(
              // 	htmlString.indexOf("<body>"),
              // 	htmlString.indexOf("</body>")
              // );
              let htmlBody = htmlString.substring(
                htmlString.indexOf("<body>"),
                htmlString.indexOf("</body>") + 7
              );
              console.log(htmlBody);
              resolve(htmlBody);
            });
        } else {
          //https://songcpu1.cse.ust.hk/contracfy/api/docx-to-html
          //songcpu1.cse.ust.hk/contracfy-dev/api/utilities/docx-to-html
          fetch(
            "https://songcpu1.cse.ust.hk/contracfy-dev/api/utilities/docx-to-html",
            {
              method: "POST",
              body: formData,
              headers: { "x-access-token": getWebCookie("JWTtoken") },
            }
          )
            .then((res) => {
              console.log(res.status);
              return res.json();
            })
            .then((result) => {
              // console.log(
              //   "After Upload Result Is ---->",
              //   result.converted_file
              // );
              let htmlString = result.converted_file;
              // console.log(
              // 	htmlString.indexOf("<body>"),
              // 	htmlString.indexOf("</body>")
              // );
              let htmlBody = htmlString.substring(
                htmlString.indexOf("<body>"),
                htmlString.indexOf("</body>") + 7
              );
              console.log(htmlBody);
              resolve(htmlBody);
            });
        }
      } catch (err) {
        console.log(err);
        resolve(false);
      }
    });
  },
  fetchDocumentTitle: async () => {
    // console.log(location.search);
    const searchParams = new URLSearchParams(
      window.location.hash.split("?")[1]
    );
    const docId = searchParams.get("id");
    // console.log(docId, "doc.id");
    const docRef = doc(db, "files", docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const documentTitle = docSnap
        .data()
        .fileName.split("_")
        .slice(1)
        .join("_");
      return documentTitle;
    } else {
      alert("Document does not exist!");
      return;
    }
  },

  getCookie: (name) => {
    return getWebCookie(name);
  },

  //删除cookie
  delCookie: (name) => {
    deleteWebCookie(name);
  },

  setCookie: (name, data, path = "/", domain = "/", expire = 180000) => {
    setWebCookie(name, data, path, domain, expire);
  },

  createNewInstanceInFirebase: async (document) => {
    const {
      id,
      tags,
      title,
      url,
      userId,
      lastOpened,
      lastOpenedTimestamp,
      lastUpdated,
      lastUpdatedTimestamp,
    } = document;

    const fileRef = await collection(db, "files");
    const queryRef = query(fileRef);
    const fileSnap = await getDocs(queryRef);
    const fileName = `${userId}_${title}`;
    const existingFilesWithSameName = await fileSnap.docs.filter((doc) => {
      if (doc.data().fileName.startsWith(fileName)) {
        return true;
      }
      return false;
    });

    const newFileName = generateNewTitle(fileName, existingFilesWithSameName);

    const storageRef = ref(storage, `uploadedDocs/${newFileName}`);
    console.log(storageRef);
    await uploadBytes(
      storageRef,
      await fetch(url)
        .then((res) => res.blob())
        .catch((err) => console.error(err))
    );
    const newFileURL = await getDownloadURL(storageRef);

    const newFileData = {
      fileName: newFileName,
      fileLink: newFileURL,
      lastOpenTime: new Date(),
      lastUpdateTime: new Date(),
      tags: tags,
      userId: userId,
    };
    const filesCollection = collection(db, "files");
    await addDoc(filesCollection, newFileData).catch((err) => console.log(err));
    return newFileData;
  },

  /**
   * Return the corresponding document content and title
   * @param {String} docID
   * @return {String} result
   * @return {String} title
   */
  getDocumentTitleAndContent: async () => {
    const searchParams = new URLSearchParams(
      window.location.hash.split("?")[1]
    );
    const docId = searchParams.get("id");
    // console.log(docId, "doc.id");
    const docRef = doc(db, "files", docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.table(docSnap.data());
      const fileLink = docSnap.data().fileLink;
      const originalTitle = docSnap.data().fileName;
      const documentTitle = docSnap
        .data()
        .fileName.split("_")
        .slice(1)
        .join("_");

      const response = await fetch(fileLink);
      const blob = await response.blob();

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function () {
          const text = reader.result;
          resolve({
            result: text,
            title: documentTitle,
            titleWithId: originalTitle,
          });
        };
        reader.readAsText(blob);
      });
    } else {
      alert("Document does not exist!");
      return;
    }
  },

  deleteTagFromDocuments: async function (
    tag,
    setTagList,
    tagList,
    setSelectedTag
  ) {
    const batch = writeBatch(db);
    const userId = getCurrentUser();
    const documentListQuery = query(
      collection(db, "files"),
      where("tags", "array-contains", tag),
      where("userId", "==", userId)
    );
    const documentList = await getDocs(documentListQuery);
    documentList.forEach((doc) => {
      const docRef = doc.ref;
      const docData = doc.data();
      const updatedTags = docData.tags.filter((docTag) => {
        return docTag !== tag;
      });

      batch.update(docRef, { tags: updatedTags });
    });
    try {
      await batch.commit();
      setTagList(
        tagList.filter((t) => {
          return t !== tag;
        })
      );
      setSelectedTag("All document");
    } catch (error) {
      console.log("Error updating documents: ", error);
    }
  },

  saveContentToFirebase: async function (content, title) {
    const fileRef = ref(storage, `uploadedDocs/${title}`);
    const updatedBlob = new Blob([content], { type: "text/html" });
    uploadBytes(fileRef, updatedBlob)
      .then(() => {
        console.log("HTML file content updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating HTML file content:", error);
      });
  },

  //Returns A Document List from Firestore
  getAllDocuments: async function () {
    const documents = [];
    const userId = getCurrentUser();
    if (!userId) {
      return;
    }

    try {
      const q = query(collection(db, "files"), where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        documents.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return documents;
    } catch (error) {
      console.error("Error fetching documents:", error);
      return [];
    }
  },
};

const getCurrentUser = () => {
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  return userId;
};

const setWebCookie = (
  name,
  data,
  path = "/",
  domain = "/",
  expire = 180000
) => {
  // document.cookie = "JWTtoken=" + data + ";path='/portal'"
  let time = new Date();
  time.setTime(time.getTime() + expire);
  document.cookie =
    name +
    "=" +
    data +
    ";path=" +
    path +
    // + ";domain=" + domain
    ";expires=" +
    time.toGMTString();
  logger.logInfo(4, "setCookie: ", name);
};

const getWebCookie = (name) => {
  let arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
  if (arr != null) return unescape(arr[2]);
  return null;
};

const deleteWebCookie = (name) => {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getWebCookie(name);
  logger.logInfo(5, "Saved cookies", cval);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
};

/**
 * Take a title and a list of files, generate the new Title according to the number of existing files with
 * the given title
 * @param {*} title
 * @param {*} fileList
 * @return {*} newTitle
 */
const generateNewTitle = (title, fileList) => {
  let newTitle = title;
  const existingTitles = fileList.map((file) => file.data().fileName);
  console.log(existingTitles);
  let index = 1;
  while (existingTitles.includes(newTitle)) {
    newTitle = `${title}(${index})`;
    index++;
  }
  return newTitle;
};

export default API;
