import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Breadcrumbs, Stack, Typography, Link, Grid } from "@mui/material";
import "./blogpage.css";
import UploadPage from "./UploadPage";
import useFetch from "../../hooks/useFetch";
import DocumentTable from "./DocumentTable";
import { getAuth } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../Firebase";
import { useContext } from "react";
import TagContext from "../../hooks/TagContext";
import theme from "../../theme/theme";
import ProjectContext from "../../hooks/ProjectContext";
import TagButtons from "./TagButtons";

// ----------------------------------------------------------------------

export default function BlogPage() {
  const {
    selectedTag,
    setSelectedTag,
    openAddTag,
    setOpenAddTag,
    handleOpenAddTag,
    handleCloseAddTag,
    handleAddTag,
    tagList,
    setTagList,
  } = useContext(TagContext);
  const project = useContext(ProjectContext);
  const navigate = useNavigate();
  const [data] = useFetch();
  const [documents, setDocuments] = useState([]);
  const auth = getAuth();
  const handleDeleteCallback = () => {
    navigate("/portal/home");
  };
  /**
   * Takes a timestamp and returns a formatted date
   *
   * @param {Timestamp} timestamp
   * @return {String} formattedDate
   */
  const convertTimestampToDate = (timestamp) => {
    const _date = timestamp.toDate();

    const formattedDate = `${_date.getFullYear()}/${(_date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${_date.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  useEffect(() => {
    var allDocuments = [];
    const uid = auth.currentUser.uid;
    const q = query(collection(db, "files"), where("userId", "==", uid));
    const unsubscribeFromFirestore = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const fetchedDocument = change.doc.data();
        const _title = fetchedDocument.fileName.split("_").slice(1).join("_");
        const _lastUpdated = convertTimestampToDate(
          fetchedDocument.lastUpdateTime
        );
        const _lastOpened = convertTimestampToDate(
          fetchedDocument.lastOpenTime
        );
        const fileData = {
          id: change.doc.id,
          title: _title,
          url: fetchedDocument.fileLink,
          userId: fetchedDocument.userId,
          lastUpdated: _lastUpdated,
          lastOpened: _lastOpened,
          lastUpdatedTimestamp: fetchedDocument.lastUpdateTime,
          lastOpenedTimestamp: fetchedDocument.lastOpenTime,
          tags: fetchedDocument.tags,
          projects: fetchedDocument.projects,
        };

        if (change.type === "added" && !allDocuments.includes(fileData)) {
          allDocuments = [...allDocuments, fileData];

          setDocuments((prevDocuments) => [...prevDocuments, fileData]);
          let _tagList = [...tagList];
          fetchedDocument.tags?.forEach((tag) => {
            if (!_tagList.includes(tag)) {
              _tagList = [..._tagList, tag];
            }
          });

          // Handles Project List Initialization
          if (fetchedDocument.projects) {
            project.setProjectList((prevProjectList) => {
              const newProject = fetchedDocument.projects?.filter(
                (project) => !prevProjectList.includes(project)
              );
              return [...prevProjectList, ...newProject];
            });
          }

          setTagList((prevTagList) => {
            const newTags = fetchedDocument.tags.filter(
              (tag) => !prevTagList.includes(tag)
            );
            return [...prevTagList, ...newTags];
          });
        } else {
          const index = allDocuments.findIndex((doc) => doc.id === fileData.id);
          if (index !== -1) {
            if (change.type === "removed") {
              allDocuments.splice(index, 1);
              setDocuments(allDocuments);
            } else if (change.type === "modified") {
              setDocuments((prevDocuments) => {
                return prevDocuments.map((doc) => {
                  if (doc.id === fileData.id) {
                    return fileData;
                  }
                  return doc;
                });
              });
            }
          }
        }
      });
    });
    return () => unsubscribeFromFirestore();
  }, []);

  if (!data) {
    return (
      <div>No files</div> //no files in the database
    );
  }

  // Breadcrumbs on the top of the page
  const BreadCrumb = () => {
    let breadcrumbs = ["Homepage"];

    if (project.selectedProject === "" || project.selectedProject === null) {
      breadcrumbs = ["Homepage"];
    } else {
      breadcrumbs = ["Homepage", project.selectedProject];
    }
    const handleHomepageClick = () => {
      project.onSelectProject("All document");
    };
    return (
      <Stack spacing={2} sx={{ height: "5vh" }}>
        <Breadcrumbs
          aria-label="breadcrumbs"
          separator={
            <Typography variant="SubTitle" color={theme.palette.midGrey[2]}>
              {">"}
            </Typography>
          }
        >
          <Link onClick={handleHomepageClick} underline="none">
            <Typography variant="SubTitle" color={theme.palette.midGrey[2]}>
              {breadcrumbs[0]}
            </Typography>
          </Link>
          {project.selectedProject.length > 0 && (
            <Typography variant="SubTitle" color={theme.palette.midGrey[2]}>
              {breadcrumbs[1]}
            </Typography>
          )}
          <TagButtons tagList={[selectedTag]} onSelect={() => {}}></TagButtons>
        </Breadcrumbs>
      </Stack>
    );
  };

  return (
    <div className="dashboard-container">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* Breadcrumb */}
          <BreadCrumb />
        </Grid>
        <Grid item xs={12}>
          <UploadPage />
        </Grid>
        <Grid item xs={12}>
          {/* Document Table */}
          <div className="document-table">
            <DocumentTable
              documents={documents}
              handleDeleteCallback={handleDeleteCallback}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
              openAddTag={openAddTag}
              setOpenAddTag={setOpenAddTag}
              handleOpenAddTag={handleOpenAddTag}
              handleCloseAddTag={handleCloseAddTag}
              handleAddTag={handleAddTag}
              tagList={tagList}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
