import { React, useState } from "react";
import "./App.css";
import {
  HashRouter,
  Routes,
  Route,
  Navigate,
  Link,
  BrowserRouter,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import Portal from "./Portal";

import Review from "./pages/Review";
import Support from "./Support";
import Draft from "./pages/Draft/Draft";

import TermsExplanation from "./pages/TermsExplanation/TermsExplanation";
// import DashboardLayout from "./Dashboard/layouts/dashboard";
import DashboardPage from "./pages/DashboardAppPage";
import UserPage from "./pages/UserPage";
// import Nav from './Dashboard/layouts/dashboard/nav'

import Page404 from "./pages/Page404";
import UIDev from "./pages/ui-dev/UIDev";
import Abstract from "./pages/Abstract/Abstract";

function App() {
  const pathname = window.location.href;
  const [open, setOpen] = useState(false);
  //pathname checking will depend on the domain name (localhost:3000 in local)

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} exact />
        <Route path="/login" element={<Login />} />
        <Route path="/contracfy/login" element={<Login />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/ui-dev" element={<UIDev />}></Route>
        <Route element={<Portal />}>
          <Route
            path="/portal"
            element={<Navigate to="/portal/home" />}
          ></Route>
          <Route path="/portal/home" element={<DashboardPage />} />
          <Route
            path="/portal/terms-explanation"
            element={<TermsExplanation />}
          />
          <Route path="/portal/home/user" element={<UserPage />} />
          <Route path="/portal/support" element={<Support />} />
          <Route path="/portal/review" element={<Review />} />
          <Route path="/portal/draft" element={<Draft />} />
          <Route path="/portal/abstract" element={<Abstract />} />
        </Route>
        <Route path="/*" element={<Navigate to="/404" />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
