import { Outlet, useLocation, Navigate } from "react-router-dom";
import ChatBox from "./Components/ChatBox";
import styled from "styled-components";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import API from "./Utils/API";

import TopBar from "./Components/topbar";

import { getAuth } from "firebase/auth";
import { Box, Grid } from "@mui/material";

import LeftNav from "./Components/LeftNav";
import { TagProvider } from "./hooks/TagContext";
import { DocumentProvider } from "./hooks/DocumentContext";
import { ProjectProvider } from "./hooks/ProjectContext";
import { FirestoreProvider } from "./hooks/FirestoreContext";

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: 100vh;
  box-sizing: border-box;
`;

function Portal() {
  let location = useLocation();
  const { t } = useTranslation();
  const [isChecking, setIsChecking] = useState(true);
  const [token, setToken] = useState(API.getCookie("JWTtoken"));

  const auth = getAuth();

  useEffect(() => {
    const unSubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((idToken) => {
          API.setCookie("JWTtoken", idToken, "/", "/", 180000);
          setToken((prev) => idToken);
        });
      }
      setIsChecking((prev) => false);
    });

    return () => unSubscribe();
  }, []);

  if (isChecking) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "calc(50% - 20px)",
          left: "calc(50% - 20px)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  //Deprecated
  const checkAuth = async () => {
    try {
      await getAuth().onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            API.setCookie("JWTtoken", idToken, "/", "/", 1800000);
            console.log("reset Cookies");
          });
          return true;
        }
      });
    } catch (error) {
      console.log(error);
    }
    console.log("firebase auth fail");
    return false;
  };

  if (!token) {
    // 未登入，使用Navigate组件重定向到登录页，传入state属性以保存当前URL位置信息
    return (
      <Navigate
        to="/login"
        state={{ action: "login", from: location }}
        replace
      />
    );
  }

  if (location.pathname === "/login" || location.pathname === "/") {
    return (
      <Container>
        <Outlet></Outlet>
      </Container>
    );
  } else if (
    location.pathname.includes("/portal/review") ||
    location.pathname.includes("/portal/draft") ||
    location.pathname.includes("/portal/terms-explanation") ||
    location.pathname.includes("/portal/abstract")
  ) {
    //Document Pages
    return (
      <Container>
        <DocumentProvider>
          <TopBar></TopBar>
          <Outlet></Outlet>
          {/* <ChatBox></ChatBox> */}
        </DocumentProvider>
      </Container>
    );
  } else {
    return (
      <Box sx={{ padding: "0.5rem" }}>
        <Grid container spacing={4}>
          <TagProvider>
            <ProjectProvider>
              <FirestoreProvider>
                <Grid item xs={3}>
                  <LeftNav />
                </Grid>
              </FirestoreProvider>
              <Grid item xs={9}>
                <Outlet />
              </Grid>
            </ProjectProvider>
          </TagProvider>
        </Grid>
        <ChatBox />
      </Box>
    );
  }
}

export default Portal;
