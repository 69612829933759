const DataAPI = {
  getRecommendation: async (content) => {
    const url = process.env.REACT_APP_GET_RECOMMENDATION;
    console.log(url);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });
      const data = await response.json();
      const result = data?.recommendations?.map((item) => ({
        ...item,
        title: "Based on your contract you may need:",
        active: true,
      }));

      return result;
    } catch (error) {
      console.error(error);
    }
  },
  getTerms: async (content) => {
    const url = process.env.REACT_APP_GET_EXTRACT_CONTRACT_DETAILS;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });

      if (!response.ok) {
        throw new Error(`${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      // const result = data.result;
      // return result;
      return data;
    } catch (error) {
      // console.log("Error Caught");
      throw error;
    }
  },
  getExtractiveQA: async (content) => {
    // console.trace("Get QA");
    const url = process.env.REACT_APP_GET_EXTRACTIVE_QA;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },
  getQA: async (content, question) => {
    // console.trace("Get QA");
    const url = process.env.REACT_APP_GET_QA;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          document: { content: content },
          question: { question: question },
        }),
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  },

  getAnswer: async (question) => {
    // console.log(JSON.stringify({ question }));
    const url = process.env.REACT_APP_GET_RESPONSE;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question }),
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },
  getAbstract: async (content) => {
    const url = process.env.REACT_APP_SUMMARIZE_DOCUMENT;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });
      const data = await response.json();
      const result = data.result;
      console.table({ data: data, result: result });
      return result;
    } catch (error) {
      console.error(error);
    }
  },
};

export default DataAPI;
