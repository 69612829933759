import { useState, useEffect } from "react";
import { db } from "../Firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function useFetch() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState("");

  async function fetchUrl() {
    const documentFromFirestore = await fetchFromFirestore(uid);
    // console.log(documentFromFirestore);
    if (!documentFromFirestore) {
      // Use preset data first, later change to Loading state
      setLoading(true);
    } else {
      //Dummy Data for testing purposes, later will be changed to Firestore data
      setData(documentFromFirestore);
      // Handle data from Firestore
      setLoading(false);
    }
  }

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (response) => {
      if (response) {
        setUid(response.uid);
        // console.table(uid);
      } else {
        setUid("");
      }
    });
  });
  useEffect(() => {
    if (uid !== "") fetchUrl();
  }, [uid]);

  return [data, loading];
}

/**
 * Fetch file list from Firestore
 *
 * @return {*}
 */
const fetchFromFirestore = async (uid) => {
  if (uid === "") {
    return;
  }

  const filesCollection = collection(db, "files");
  // console.log(uid);
  const querySnapshot = await getDocs(
    query(filesCollection, where("userId", "==", uid))
  );
  const documents = [];
  querySnapshot.forEach((doc) => {
    const _title = doc.data().fileName.split("_")[1].split(".")[0];
    const _lastUpdated = convertTimestampToDate(doc.data().lastUpdateTime);
    const _lastOpened = convertTimestampToDate(doc.data().lastOpenTime);

    const _data = {
      id: doc.id,
      title: _title,
      url: doc.data().fileLink,
      userId: doc.data().userId,
      lastUpdated: _lastUpdated,
      lastOpened: _lastOpened,
      lastUpdatedTimestamp: doc.data().lastUpdateTime,
      lastOpenedTimestamp: doc.data().lastOpenTime,
      tags: doc.data().tags,
    };
    // console.log(doc.id);
    documents.push(_data);
  });
  // console.table(documents);
  return documents;
};

/**
 * Takes a timestamp and returns a formatted date
 *
 * @param {Timestamp} timestamp
 * @return {String} formattedDate
 */
export const convertTimestampToDate = (timestamp) => {
  const _date = timestamp.toDate();

  const formattedDate = `${_date.getFullYear()}/${(_date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${_date.getDate().toString().padStart(2, "0")}`;
  return formattedDate;
};
