import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  List,
  ListItemButton,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/system";
import { KeyboardArrowDown } from "@mui/icons-material";

const DiagonalBorderTab = styled(Tab)(({ theme }) => ({
  alignItems: "flex-start",
  paddingLeft: "4%",
  position: "relative",
  overflow: "hidden",
  marginLeft: "-2%",
  backgroundColor: theme.palette.midGrey[3],
  width: "100%",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
  clipPath: "polygon(100% 0, 90% 0, 100% 100%, 0 100%, 0 0)",
  "&.Mui-selected": {
    backgroundColor: "#FFF",
    zIndex: "1",
  },
}));

const TermsItems = styled(ListItemButton)(({ theme }) => ({
  fontFamily: "Raleway",
}));
const TabPanel = (props) => {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`Terms-panel-${index}`}
      aria-labelledby={`Terms-panel-${index}`}
      key={`Terms-panel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ backgroundColor: "#FFFFFF" }}>{children}</Box>
      )}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const PanelList = (props) => {
  const { items } = props;
  const [open, setOpen] = useState("");

  const handleOnClick = (key) => {
    if (open !== key) {
      setOpen(key);
      return;
    } else {
      setOpen("");
    }
  };

  return (
    <>
      {items &&
        Object.entries(items).map(([key, items], index) => {
          if (index === 0) {
            return null;
          }
          return (
            <List sx={{ width: "100%" }} key={`Terms-Type-${index}`}>
              <ListItemButton
                onClick={() => handleOnClick(key)}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ textTransform: "capitalize" }}>
                  {key}
                </Typography>
                <KeyboardArrowDown />
              </ListItemButton>
              <Collapse
                in={open === key}
                timeout="auto"
                unmountOnExit
                key={`collapse-${index}`}
              >
                {items.map((item, index) => {
                  return (
                    <TermsItems
                      key={`item-${index}`}
                      className="list-terms-item"
                    >
                      {item.detail}
                    </TermsItems>
                  );
                })}
              </Collapse>
            </List>
          );
        })}
    </>
  );
};

function a11yProps(index) {
  return {
    id: `Terms-tab-${index}`,
    "aria-controls": `Terms-tabpanel-${index}`,
  };
}

const TermsTable = (props) => {
  const [value, setValue] = useState(0);
  const { filteredExplanation } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          {!filteredExplanation && (
            <Typography> No Explanations Found</Typography>
          )}
          {filteredExplanation &&
            filteredExplanation.map((party, index) => {
              return (
                <DiagonalBorderTab
                  className="diagonalBorder"
                  key={index}
                  label={party.party}
                  value={index}
                  {...a11yProps(index)}
                  disableRipple
                />
              );
            })}
        </Tabs>
      </Box>

      {filteredExplanation &&
        filteredExplanation.map((party, index) => {
          return (
            <TabPanel value={value} index={index} key={`Terms-panel-${index}`}>
              <PanelList items={party} key={`Terms-panel-list-${index}`} />
            </TabPanel>
          );
        })}
    </Box>
  );
};

export default TermsTable;
