import * as React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export default function BasicPopover(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoad] = React.useState(true);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLoad(true);
  };

  const afterLoaded = (e) =>{
    e.target.style.visibility='visible'
    setLoad(false)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <span style={{"zIndex": 400}}>
    <span id={props.id} onMouseDown={handleClick}>
      {props.content}

    </span>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx:{borderRadius: "1vw", boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",maxWidth: '22vw', maxHeight: '30vh'},
        }}
        
      >
        {/* <CardMedia component="iframe" src={props.url} sx={{ width: '22vw', height: '40vh', px:2}}>
        </CardMedia> */}

        {loading ? 
          <Box  
            sx={{display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: "100%",
                height: "30vh",
                flexDirection: 'column',
                bgcolor: 'background.paper',
                borderRadius: 1}}>
            <CircularProgress color="primary" size={"5rem"} /> 
            <Typography>Accessing Data...</Typography>
          </Box>
          : null}

        
        <iframe onLoad={afterLoaded} title={props.content} src={props.url} style={{width: '22vw', height: '40vh', padding:"0 2px", visibility: 'hidden'}}></iframe>
        {/* <iframe title={props.content} url={props.url} style={{width: '22vw', height: '40vh', padding:"0 2px"}}></iframe> */}
      </Popover>
    </span>
  );
}
