import React, { useEffect, useState } from "react";
import TinyEditorComponentClassic from "../../Components/TinyEditorComponentClassic.js";
import EntityTable from "../../Components/EntityTable.js";
import { db } from "../../Firebase.js";
import { doc, getDoc } from "firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import TrafficLight from "../../Components/TrafficLight.js";
import ReviewImage from "../../Image/contractReview.jpg";
import demoText from "../../_mock/Demo.js";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { InputAdornment, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";

function NewHome() {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("Empty");
  const [rowData, setRowData] = useState("");
  const [demo, setDemo] = useState(false);
  useEffect(() => {
    fetchDocument();
  }, []);

  const fetchDocument = async () => {
    // console.log(location.search);
    const searchParams = new URLSearchParams(
      window.location.hash.split("?")[1]
    );
    const docId = searchParams.get("id");
    // console.log(docId, "doc.id");
    const docRef = doc(db, "files", docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.table(docSnap.data());
      const fileLink = docSnap.data().fileLink;
      const documentTitle = docSnap.data().fileName.split("_")[1];
      // setTitle(documentTitle);
      fetch(fileLink)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = function () {
            const text = reader.result;
            setContent(text);
          };
          reader.readAsText(blob);
        });
    } else {
      alert("Document does not exist!");
      return;
    }
  };

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };
  const showDemoText = (num) => {
    if (num === 0) {
      setContent(demoText.TextA);
    } else if (num === 1) {
      setContent(demoText.TextD);
    } else {
      setContent(demoText.TextC);
    }
  };

  const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
  };

  let handleSubmit = () => {
    console.log("TITLE ", title);
    // console.log("CONTENT", content);
    var strippedHtml = content.replace(/<[^>]+>/g, "");
    var x = strippedHtml.replace(/(?![^<]*>)&ldquo;(.*?)(?<!=)&rdquo;(?!>)/);
    console.log(x);

    console.log("string content", strippedHtml);
    setLoading(true);
    let JWTtoken = getCookie("JWTtoken");
    //https://songcpu1.cse.ust.hk/contracfy-dev/api/documents/processDocument
    fetch(
      "https://songcpu1.cse.ust.hk/contracfy-dev/api/documents/processDocument",
      {
        method: "POST",
        body: JSON.stringify({ content: strippedHtml }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "x-access-token": JWTtoken,
          // 'x-access-token':  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImN5bndlbGwiLCJpYXQiOjE2NzY2MzcxNjMsImV4cCI6MTcwODE5NDc2M30.Wfc8Akj3Lo8AVqiin4g7zbrAwy63_F3tkiXA-3Kc7Mw'
        },
      }
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((data) => {
        console.log("data", data);
        setLoading(false);
        // setCheck(!hasChecked);
        setRowData(data.result);
        setContent(data.content);
        // processReviewEntity(data.result.content, data.result.result);
      });
  };
  // const processReviewEntity = (contractText, data) => {
  // 	let rows = [];
  // 	let contractList = contractText.split("");
  // 	for (var i = 0; i < data.length; i++) {
  // 		if (data[i].kb_id === "") {
  // 			continue;
  // 		}
  // 		let beginPos = data[i].start;
  // 		let endPos = data[i].end;
  // 		let url = data[i].kb_id;
  // 		contractList[beginPos] =
  // 			'<span class=entity id="entity_' +
  // 			beginPos +
  // 			'" url= ' +
  // 			url +
  // 			">" +
  // 			contractList[beginPos];
  // 		contractList[endPos] = contractList[endPos] + "</span>";

  // 		let row = {
  // 			id: "entity_" + beginPos,
  // 			color: i % 2 ? "#FAFAFA" : "#FFFFFF",
  // 			type: "entity",
  // 			title: data[i].label,
  // 			content: url,
  // 		};
  // 		rows.push(row);
  // 	}
  // 	console.log(rows);
  // 	setRowData(rows);
  // 	setContent(contractList.join(""));
  // };

  const showSummary = () => {
    console.log(rowData);
    return <EntityTable rows={rowData} content={content} />;
  };
  const showDemo = () => {
    setDemo(!demo);
  };
  const loadDemo = () => {
    if (demo === true) {
      return (
        <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
          <Button
            variant="contained"
            onClick={() => {
              showDemoText(0);
            }}
          >
            Load Demo Text 1
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              showDemoText(1);
            }}
          >
            Load Demo Text 2
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              showDemoText(2);
            }}
          >
            Load Demo Text 3
          </Button>
        </Stack>
      );
    } else {
      return;
    }
  };
  return (
    <div
      style={{
        marginTop: "35px",
        display: "flex",
      }}
    >
      <div style={{ width: "50%", height: "100vh" }}>
        <TinyEditorComponentClassic
          content={content}
          updateContent={handleContentChange}
        />
      </div>
      <div
        style={{
          width: "50%",
          height: "100vh",
          display: "flex",
          justifyContent: rowData?.length > 0 ? "flex-start" : "center",
          alignItems: rowData?.length > 0 ? "flex-start" : "center",
          backgroundColor: "white",
        }}
      >
        <div>
          <img
            src={ReviewImage}
            alt="reviewImage"
            onClick={showDemo}
            style={{
              display: rowData.length > 0 ? "none" : "block",
              margin: "auto",
              height: "15vh",
              width: "24.18vh",
            }}
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
            style={{ display: rowData?.length > 0 ? "none" : "block" }}
          >
            Start to Review
          </LoadingButton>
          {loadDemo()}
        </div>
        <div
          style={{
            marginTop: "1vh",
            width: "100%",
            display: rowData?.length > 0 ? "block" : "none",
          }}
        >
          <div
            style={{
              width: "100%",
              borderLeft: "1px solid lightgrey",
              backgroundColor: "white",
            }}
          >
            <Paper elevation={3} style={{ padding: "2vh" }}>
              <Typography
                variant="h5"
                textAlign="center"
                fontSize="20px"
                style={{ color: "#666666", fontFamily: "Raleway" }}
              >
                Term Explanation
              </Typography>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <div style={{ width: "100%" }}>
                  <TextField
                    placeholder="Any term or clause you would like to know more?"
                    // value={searchTerm}
                    variant="standard"
                    // onChange={handleSearchChange}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: "12px",
                        textAlign: "center",
                        justifyItems: "center",
                        color: "#959595 ",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "30px",
                    color: "#525252",
                    borderColor: "#F8C218",
                    backgroundColor: "#FFF8E2",
                  }}
                  // onClick={handleSearch}
                >
                  Search
                </Button>
              </div>
            </Paper>
            {showSummary()}
          </div>
        </div>
      </div>

      <TrafficLight />
    </div>
  );
}

export default NewHome;
