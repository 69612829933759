const logNum = {
    0: "OFF",
    1: "FATAL",
    2: "ERROR",
    3: "WARNING",
    4: "INFO",
    5: "DEBUG"
}
/**
 * Logger is used to log the information based on level and vebosity
 * @class Logger
 * @param {int} vebo configuration vebosity
 * @returns 
 */
var Logger = function(vebo = 5){
    if (!(this instanceof Logger)) return new Logger(vebo);
    this.verbosity = vebo;
};

/**
 * Used to log the message
 * @method
 * @for Logger
 * @param {int} level message level
 * @param {string} msg message
 * @returns null
 */
Logger.prototype.logInfo = function(level = 5, msg, otherStr = ""){
    if(level > 5 || level < 0){
        return;
    }
    if(this.verbosity >= level){
        console.log("%s [%s] %s",new Date(),logNum[level],msg, otherStr);
    }
};

/**
 * Set the vebosity based on configuration
 * @method
 * @for Logger
 * @param {int} vebo target vebosity
 */
Logger.prototype.setVebo = function(vebo){
    this.verbosity = vebo;
}

module.exports = Logger;
