import { useEffect, useState } from "react";
import TinyEditorComponentTermsSuggestion from "../../Components/TinyEditorComponentTermsSuggestion";
import DataAPI from "../../Utils/DataAPI";
import {
  Box,
  CircularProgress,
  ThemeProvider,
  Typography,
} from "@mui/material";
import theme from "../../theme/theme";
import API from "../../Utils/API";
import "./drafting.css";
import TrafficLight from "../../Components/TrafficLight";
import TermsTable from "./Component/TermsTable";

function TermsExplanation() {
  //TODO: For error handling: loading, error, and value for data
  const [content, setContent] = useState({
    loading: true,
    error: null,
    value: null,
  });

  const [explanation, setExplanation] = useState({
    loading: true,
    error: null,
    value: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClause, setSelectedClause] = useState(null);
  const [filteredExplanation, setFilteredExplanation] = useState(null);

  useEffect(() => {
    //Hides Chatbox
    const chatboxSelector = document.querySelector(".ChatBox");
    if (chatboxSelector) {
      chatboxSelector.style.display = "none";
    }

    //Fetch Content and Explanation
    const fetchData = async () => {
      try {
        const data = await API.getDocumentTitleAndContent();
        const result = data.result;

        setContent({ loading: false, error: null, value: result });
        await fetchExplanation(result);
      } catch (error) {
        setContent({ loading: false, error: error, value: null });
      }
    };

    const fetchExplanation = async (documentContent) => {
      try {
        const explanationResponse = await DataAPI.getTerms(documentContent);
        if (explanationResponse.error) {
          setExplanation({
            loading: false,
            error: explanationResponse.error,
            value: null,
          });
          return;
        } else {
          setExplanation({
            loading: false,
            error: null,
            value: explanationResponse,
          });
        }
      } catch (error) {
        setExplanation({
          loading: false,
          error: `Error handling requests. Please try again. (${error})`,
          value: null,
        });
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (explanation.error || explanation.value === null) {
      return;
    } else {
      var _filteredExplanation = [...explanation?.value];
      if (searchTerm !== "") {
        let _searchTerm = searchTerm.toLowerCase();
        _filteredExplanation = explanation.filter((entry) => {
          return (
            entry.term.toLowerCase().includes(_searchTerm) ||
            entry.explanation.toLowerCase().includes(_searchTerm)
          );
        });
      }
      setFilteredExplanation(_filteredExplanation);
    }
  }, [searchTerm, explanation]);

  // const handleSelect = (clause) => {
  //   // console.log(clause);
  //   setSelectedClause(clause);
  //   scrollToTerm();
  //   // console.log(`Called on ${jargon.highlighted_text}, ${selectedJargon}`);
  // };
  const handleContentChange = (newContent) => {
    setContent({ loading: false, error: null, value: newContent });
  };

  return (
    <ThemeProvider theme={theme}>
      <TrafficLight />
      <Box
        display="flex"
        height="94vh"
        sx={{ backGroundColor: "#FAFAFA", marginTop: "6vh" }}
      >
        {/* Left Box */}
        <Box width="50%">
          {content.error && <Typography>{explanation.error}</Typography>}
          {content.loading && <CircularProgress />}
          {content.value && (
            <TinyEditorComponentTermsSuggestion
              content={content.value}
              updateContent={handleContentChange}
              selectedClause={selectedClause}
            />
          )}
        </Box>
        {/* Right Box */}
        <Box
          width="50%"
          display={"flex"}
          flexDirection={"column"}
          alignContent={"center"}
          bgcolor={"#FAFAFA "}
        >
          <Box sx={{ borderBottom: " 1px solid #EAEAEA" }}>
            <Typography variant="h4" textAlign="center" fontSize="20px">
              Term Explanation
            </Typography>

            {explanation.error && (
              <Typography>{explanation.error.toString()}</Typography>
            )}
            {explanation.loading && <CircularProgress />}

            {filteredExplanation && (
              <TermsTable filteredExplanation={filteredExplanation} />
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default TermsExplanation;
